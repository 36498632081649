import React from 'react';

import { Box, Button, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Text from 'components/Text';

import Theme from 'style/Theme';
import * as S from '../../../../styles';

const textContent = {
  display: 'flex',
  flexDirection: 'column',
	maxHeight: '400px',
	overflowY: 'scroll',
	overflowX: 'hidden',
  padding: 10,
  color: '#fff',
  backgroundColor: Theme.dark.secondary,
}

const ModalViewContent = ({ onClose, selectedContent }) => {
  return (
    <>
      <S.Box>
        <div>
          <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
            <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
              <CloseIcon style={{ color: Theme.dark.primaryText }} />
            </Button>
          </div>

          <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
            Conteúdo
          </Text>
        </div>
        <Grid
          container
          display="flex"
          direction="column"
        >
          {
            !!selectedContent.ObjetoAprendizagem.video && (
              <>
                <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                  Vídeo:
                </Text>
                <S.Video>
                  <iframe 
                  src={selectedContent.ObjetoAprendizagem.video}
                  frameborder="0"
                  allowFullScreen
                  >
                  </iframe>
                </S.Video>
              </>
            )
          }
        </Grid>

        <Grid
          container
          display="flex"
          direction="column"
        >
          {
            !!selectedContent.ObjetoAprendizagem.audio && (
              <>
                <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                  Áudio:
                </Text>
                <div>
                  <audio controls>
                    <source src={selectedContent.ObjetoAprendizagem.audio} type='audio/mpeg' />
                  </audio>
                </div>
              </>
            )
          }
        </Grid>

        <Grid
          container
          display="flex"
          direction="column"
        >
          {
            !!selectedContent.ObjetoAprendizagem.text && (
              <>
                <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                  Texto:
                </Text>
                
                <div style={textContent} dangerouslySetInnerHTML={{ __html: selectedContent.ObjetoAprendizagem.text }}></div>
              </>
            )
          }
        </Grid>
      </S.Box>
    </>
  )
}

export default ModalViewContent;