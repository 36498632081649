import API from 'api';

const create = (body) => API.post('/section', body);

const get = (query = null) => API.get(`/section?${query ? query : ''}`);

const remove = (id) => API.delete(`/section/${id}`);

const update = (body) => API.put('/section', body);

export default {
  create,
  get,
  update,
  remove,
};