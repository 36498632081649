import API from 'api';

const create = (body) => API.post('/exercises', body);

const get = (query = null) => API.get(`/exercises?${query ? query : ''}`);

const getAlternatives = (id) => API.get(`/exercises/${id}/alternatives`);

const update = (body) => API.put('/exercises', body);

const remove = (id) => API.delete(`/exercises/${id}`);

export default {
  create,
  get,
  getAlternatives,
  update,
  remove,
};