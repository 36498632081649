import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Box, Breadcrumbs, Button, CircularProgress, Grid, Link, OutlinedInput, Typography } from '@mui/material';
import { ChatBubbleOutline, Send } from '@mui/icons-material';

import Text from 'components/Text';
import CardMessage from '../CardMessage';

import Theme from 'style/Theme';
import { Formik } from 'formik';
import ErrorText from 'components/ErrorText';
import * as S from './styles';

const styleChatBox = {
	display: 'flex',
	flexDirection: 'column-reverse',
	width: '100%',
	height: '400px',
	maxHeight: '400px',
	overflowY: 'scroll',
	overflowX: 'hidden',
	backgroundColor: Theme.dark.secondary,
}

const SchemaValidation = Yup.object().shape({
	message: Yup.string()
		.required('Campo obrigatório'),
})

const ChatMessages = () => {
	const [page, setPage] = useState(0);
	const [loadMesssages, setLoadMesssages] = useState(true);
	const getMessages = useStoreActions(({ chat }) => chat.getMessages);
	const sendMessages = useStoreActions(({ chat }) => chat.sendMessages);
	const [messages = []] = useStoreState(({ chat }) => [chat.messages]);

	const { idChat } = useParams();

	useEffect(() => {
		async function fetchData() {
			await getMessages({ id: idChat, page: 0 });
			setLoadMesssages(false);
		}
		fetchData();
	}, [0]);

	const handleSubmit = ({ teenId = idChat, message, isInstructor = true }) => {
		if (loadMesssages) return;
		setLoadMesssages(true);

		if (!teenId) return toast.error('Informe o ID do usuário.');
		if (!message) return toast.error('Informe a messagem.');
		async function sendData() {
			const response = await sendMessages({
				teenId,
				message,
				isInstructor
			})

			if (response === true) getMessages({ id: idChat, page: 0 })
		}
		sendData();
		setLoadMesssages(false);
	}

	const orderedMessages = messages.sort((a, b) => (a.id > b.id ? 1 : -1))

	return (
		<>
			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Mensagem
					</Text>
				</Grid>
			</div>

			<div>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: '15px 30px 0px', color: Theme.primary }}
				>
					<Breadcrumbs aria-label="breadcrumb" color="#fff">
						<Link
							underline="hover"
							color='inherit'
							href="/chat"
							style={{ colr: '#fff' }}
						>
							Chat
						</Link>
						<Typography color='white'>Mensagens</Typography>
					</Breadcrumbs>
				</Grid>
			</div>

			{loadMesssages ? (
				<Grid container justifyContent="center" alignItems="center">
					<CircularProgress style={{ margin: 25 }} />
				</Grid>
			) : (
				<div style={{ padding: 25, backgroundColor: Theme.dark.background }}>
					{
						messages.length === 0
							?
							<Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
								Nenhum item para mostrar!
							</Text>
							:
							<Grid style={{ justifyContent: 'center', display: 'flex', overflowX: 'hidden', backgroundColor: Theme.dark.background, width: '100%', }}>
								<S.Grid>
									<Grid
										container
										direction="row"
										display="flex"
										justifyContent="start"
										alignItems="center"
										style={{ width: '100%', padding: '25px 0px', backgroundColor: Theme.dark.background }}
									>
										<ChatBubbleOutline sx={{ color: Theme.dark.primary }} />
										<Text size={24} color={Theme.dark.primaryText} style={{ paddingLeft: 9 }}>
											Mensagens
										</Text>
									</Grid>

									<Grid
										container
										justifyContent="start"
										alignItems="center"
										style={{ width: '100%', minHeight: '68px', padding: '0px 35px', marginBottom: 15, backgroundColor: Theme.dark.tertiary, borderRadius: 16 }}
									>
										<Text size={16} color={Theme.dark.primaryText}>
											Aprendiz: {messages[0]?.name}
										</Text>
									</Grid>

									<Formik
										initialValues={{
											message: '',
										}}
										validationSchema={SchemaValidation}
										onSubmit={handleSubmit}
									>
										{({ errors, handleChange, handleSubmit, touched, values }) => (
											<>
												<Box style={styleChatBox} >
													<div style={{ padding: 10 }}>
													{
														orderedMessages.map((message) =>
															<CardMessage key={message.id} message={message} idChat={idChat} page={page} isInstructor={message.isInstructor} />
														)
													}
													</div>
												</Box>

												<Grid
													container
													direction="row"
													display="flex"
													justifyContent="space-between"
													alignItems="center"
													style={{ width: '100%', marginTop: 15, paddingBottom: '0px', backgroundColor: Theme.dark.background }}
												>
													<OutlinedInput
														required
														autoFocus
														autoComplete="off"
														name='message'
														value={values.message}
														onChange={handleChange('message')}
														placeholder='Digite uma mensagem'
														sx={{ color: Theme.dark.primaryText, border: `1px solid ${Theme.dark.border}`, display: 'flex', flex: 1, height: '45px', borderRadius: '16px', backgroundColor: Theme.dark.background, padding: '0px 15px' }}
													/>

													<Button
														disabled={loadMesssages}
														onClick={handleSubmit}
														type="submit"
													>
														{loadMesssages ? <CircularProgress size={16} /> : <Send style={{ color: Theme.dark.primary, transform: 'rotate(-45deg)', marginBottom: '5px' }} />}
													</Button>
												</Grid>
												{errors.message && touched.message ? (
													<ErrorText message={errors.message} />
												) : null}
											</>
										)}
									</Formik>
								</S.Grid>
							</Grid>

					}
				</div>
			)}
		</>
	)
}

export default ChatMessages