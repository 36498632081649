import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';
import WarningApi from 'api/Warning';

export default {
  warnings: [],
  loading: false,
  count: null,

  setData: action((state, { field, payload }) => {
    state[field] = payload;
  }),

  get: thunk(async (actions, payload) => {
    try {

      const { page = 0 } = payload;

      actions.setData({ field: 'loading', payload: true });

      if (isNaN(page))
        return toast.error('erro ao receber dados de paginação da tabela');

      const query = `?page=${page}`;
      const response = await WarningApi.list(query);

      if (response.ok) {
        actions.setData({ field: 'count', payload: response?.data?.count });
        actions.setData({ field: 'loading', payload: false });
        actions.setData({ field: 'warnings', payload: response?.data.data });
        return true;
      };
      actions.setData({ field: 'loading', payload: false });
    } catch (e) {
      actions.setData({ field: 'loading', payload: false });
      console.log('error', e);
      return toast.error('erro ao listar os avisos, consulte o log de erros');
    }
  }),

  update: thunk(async (actions, payload, { getState }) => {
    try {
      const { id, title } = payload

      if (!id || isNaN(id)) return toast.error('O ID informado não está correto.');
      if (!title) return toast.error('Informe o titulo do aviso!');

      actions.setData({ field: 'loading', payload: true });
      const response = await WarningApi.update(payload);

      if (response.ok) {
        actions.setData({ field: 'loading', payload: false });
        let { warnings } = await getState();
        let foundedIndex = warnings.findIndex((t) => t.id === id);

        warnings[foundedIndex] = payload;
        actions.setData({ field: 'warnings', payload: warnings });
        toast.success('Atualizado com sucesso.');
        return true;
      }

    } catch (error) {
      console.log('warning Update exception:', error);
      return toast.error('erro desconhecido ocorreu, por favor verifique o log de erros');
    };
  }),

  remove: thunk(async (actions, payload) => {
    try {
      const { id, page = 0 } = payload;

      if (!id || isNaN(id)) return toast.error('erro ao receber o id, tente novamente');

      actions.setData({ field: 'loading', payload: true });
      const response = await WarningApi.remove(id);

      if (response.ok) {
        toast.success('Aviso removido com sucesso!');
        actions.get({ page });
        actions.setData({ field: 'loading', payload: false });
        return true;
      }

    } catch (e) {
      console.log('Warning remove exception:', e);
      return toast.error('erro desconhecido, verifique o log');
    };
  }),

  create: thunk(async (actions, payload, { getState }) => {
    try {
      const { title, type } = payload;

      if (!title) return toast.error('Informe o titulo do aviso!');
      if (!type) return toast.error('Informe o tipo do aviso.');;

      actions.setData({ field: 'loading', payload: true });
      const response = await WarningApi.create(payload);

      if (response.ok) {
        let { warnings, count } = await getState();
        warnings.push(response.data);
        count = count + 1;

        actions.setData({ field: 'count', payload: count });
        actions.setData({ field: 'warnings', payload: warnings });
        actions.setData({ field: 'loading', payload: false });
        toast.success('Aviso criado com sucesso!');
        return true;
      }

      throw 'error';
    } catch (e) {
      console.log(e);
      return toast.error('erro desconhecido, verifique o log');
    }
  })
};
