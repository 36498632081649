import styled from "styled-components";
import Theme from "style/Theme";

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: auto;

  @media(max-width: 640px) {
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
  }
`

export const Button = styled.button`
  width: 200px;
  padding: 10px;
  border-radius: 4px;
  background-color: ${Theme.dark.primary};
  border: none;
  cursor: pointer;
  
  @media(max-width: 640px) {
    width: 100%;
  }
`

export const Video = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    width: 720px;
    height: 480px;
  }

  @media (max-width: 820px) {

    iframe {
      width: auto;
      height: auto;
    }
  }
`

// Modal Create & Modal Create Exercises
export const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  min-width: 30%;
  height: auto;
  background-color: ${Theme.dark.background};
  border-radius: 4px;
  padding: 20px;
  outline: 0px;
  flex: 1;

  @media (max-width: 640px) {
    width: 80%;
  }
`