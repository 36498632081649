export const Theme = {
  background: '#FAFAFA',
  primary: '#0056D7',
  secondary: '#62A8AC',
  tertiary: '#5497A7',
  quartiary: '#50858B',
  dark: {
    background: '#1C2023',
    primary: '#4789EB',
    secondary: '#1E262D',
    tertiary: '#1E2C38',
    border: '#364563',
    primaryText: '#F2F2F3',
    secondaryText: '#CACBCE',
    success: '#2FC499',
    success20: 'rgba(47, 196, 153, 0.2)',
    danger: '#E23F57',
    poor: '#FC4F81',
    poor20: 'rgba(252, 79, 129, 0.2)',
    orange: '#E2783F',
    orange20: 'rgba(226, 120, 63, 0.2)',
    yellow: '#E2B53F',
    yellow20: 'rgba(226, 181, 63, 0.2)',
    grey: '#93979F',
    green: '#6CB32C',
    cardMessageStudent: '#144672',
    cardMessageInstrutor: '#364563'
  },
  gray: {
    primary: '#91A3AD',
    secondary: '#E5F5EC',
    tertiary: '#DFDFDF',
  },
  green: {
    primary: '#07B036',
    secondary: '#5CE157',
    tertiary: '#44c63f',
  },
  red: {
    primary: '#C0392B',
    secondary: '#E23F57',
  },
  white: {
    primary: '#FFFFFF',
  },
};

export default Theme;
