import React from 'react';
import { useNavigate } from 'react-router';
import Moment from 'moment';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import Text from 'components/Text';

import Theme from 'style/Theme';

const TableComponent = ({ chats, page = 0, handleChangePage, count = 0 }) => {
  const navigate = useNavigate();

  const handleChatId = (idChat) => {
    navigate(`/chat/${idChat}/messages`);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
      >
        <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Courses table">
          <TableHead>
            <TableRow style={{ fontWeight: 'bold' }}>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  #
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  ID_Aprendiz
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Aprendiz
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Criado em
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chats.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
              >
                <TableCell component="th" scope="row">
                  <Text color={Theme.dark.primaryText} size={15}>
                    {index}
                  </Text>
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={() => handleChatId(row.id)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.id}
                  </Text>
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={() => handleChatId(row.id)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.nome}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {Moment(row.createdAt).format('DD/MM/YYYY HH:MM')}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={25}
        rowsPerPageOptions={25}
        page={page}
        style={{ color: '#fff' }}
        onPageChange={(e, page) => handleChangePage(page)}
      />
    </>
  );
};

export default TableComponent;