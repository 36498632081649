import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Box, Breadcrumbs, Button, CircularProgress, Grid, Link, Modal, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { createBrowserHistory } from 'history';

import Text from 'components/Text';
import ModalCreate from './components/ModalCreate';
import ModalUpdate from '../ExercisesList/components/Table/components/ModalUpdate';
import Table from './components/Table';

import Theme from 'style/Theme';

const ExercisesList = ({ isAdmin = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setSshowEditModal] = useState(false);
  const [loadExercise, setLoadExercises] = useState(true);
  const { id } = useParams();
  const history = createBrowserHistory();
  
  const getExercisesList = useStoreActions(({ exercisesList }) => exercisesList.getDetails);
  const [exercises = [], exercisesList = {}] = useStoreState(({ exercisesList }) => [exercisesList.exercises, exercisesList.exercisesListDetails]);

  const openCreateModal = () => {
    setShowModal(true);
  };

  const openEditModal = () => {
    setSshowEditModal(true);
  };

  useEffect(() => {
    async function fetchData() {
      await getExercisesList(id);
      setLoadExercises(false);
    }

    fetchData();
  }, [0]);

  return (
    <>
      <div style={{ paddingTop: 60 }}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ width: '100%', height: '100%', padding: '20px', backgroundColor: Theme.dark.secondary }}
        >
          <Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
            Lista de Exercícios - Detalhes
          </Text>

          {isAdmin && (
            <div>
              <Button
                style={{ width: 200, padding: 15, borderRadius: 4, backgroundColor: Theme.dark.primary }}
                onClick={openEditModal}
              >
                <Text color={Theme.white.primary} size={16}>Editar</Text>
              </Button>
              <Button
                style={{ width: 200, padding: 15, borderRadius: 4, backgroundColor: Theme.dark.primary, marginLeft: 10 }}
                onClick={openCreateModal}
              >
                <Text color={Theme.white.primary} size={16}>Novo Exercício</Text>
              </Button>
            </div>
          )}
        </Grid>
      </div>

      <div style={{ padding: 25, paddingBottom: 0 }}>
				<Breadcrumbs aria-label="breadcrumb" color="#fff">
					<Link
						underline="hover"
						color="inherit"
						style={{ color: '#fff', cursor: 'pointer'}}
            onClick={() => history.back()}
					>
						Conteúdo da Seção
					</Link>
					
					<Typography color="white">
						{exercisesList?.title}
					</Typography>
				</Breadcrumbs>
			</div>

      {loadExercise ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress style={{ margin: 25 }} />
        </Grid>
      ) : (
        <div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background }}>
          <Text size={18} bold>
            Detalhes:
          </Text>
          <Box style={{ padding: 20, backgroundColor: Theme.dark.secondary, marginBottom: 25, borderRadius: 5, marginTop: 10 }}>
            <Text size={15}>
              Id: <b>{exercisesList?.id}</b>
            </Text>
            <Text size={15}>
              Título: <b size={18} bold>{exercisesList?.title}</b>
            </Text>
            <Text size={15}>
              Descrição: <b size={18} bold>{exercisesList?.description}</b>
            </Text>
            <Text size={15}>
              Criado em: <b size={18} bold>{exercisesList?.createdAt}</b>
            </Text>
          </Box>
          {
            exercises.length === 0
              ?
              <Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
                Nenhum exercício associado à essa lista de exercícios!
              </Text>
              : <Table exercises={exercises} isAdmin={isAdmin} />
          }
        </div>
      )}

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalCreate
          onClose={() => setShowModal(false)}
        />
      </Modal>

      <Modal
        open={showEditModal}
        onClose={() => setSshowEditModal(false)}
      >
        <ModalUpdate
          onClose={() => setSshowEditModal(false)}
          onUpdate={() => {
            window.location.reload(true)
          }}
          selectedExercisesList={exercisesList}
        />
      </Modal>
    </>
  )
}

export default ExercisesList;