import { action, thunk } from 'easy-peasy';

import UserAPI from 'api/User';

export default {
  user: {},

  setUser: action((state, payload) => {
    state.user = payload;
  }),

  getMe: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      UserAPI.me().then(response => {
        if (response.ok) {
          actions.setUser(response.data);
          localStorage.setItem('USER', JSON.stringify(response.data));
          return true;
        } else {
          throw "unathorized";
        }
      });
    } catch(e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),
};
