import { action, thunk } from "easy-peasy";
import { toast } from "react-toastify";

import ContentAPI from 'api/Content';

const errorMessage = 'ocorreu um erro inesperado, verifique sua conexão ou veja o log de erros.';

export default {
	contents: [],
	count: 0,

	setData: action((state, { type, payload }) => {
		state[type] = payload;
	}),

	get: thunk(async (actions, payload) => {
		try {
			const { page = 0, sectionId = null } = payload;

			let query = `page=${page}&sectionId=${sectionId}`;
			const response = await ContentAPI.get(query);
			if (response.ok) {
				actions.setData({ type: 'contents', payload: response?.data?.data });
				actions.setData({ type: 'count', payload: response?.data?.count });
				return response?.data;
			}
		} catch (e) {
			toast.error(e || errorMessage);
			return false;
		}
	}),

	list: thunk(async (actions, payload) => {
		try {
			const { page = 0 } = payload;

			let query = `page=${page}`;
			const response = await ContentAPI.list(query);
			console.log(response?.data)
			if (response.ok) {
				actions.setData({ type: 'contents', payload: response?.data?.data });
				actions.setData({ type: 'count', payload: response?.data?.count });
				return response?.data;
			}
		} catch (e) {
			toast.error(e || errorMessage);
			return false;
		}
	}),


	create: thunk(async (actions, payload, { getState }) => {
		try {
			const {
				exercisesId = null,
				type = undefined,
				text = null,
				video = null,
				audio = null,
				order = null
			} = payload;

			if (!type) return toast.error('Informe o tipo do conteúdo.');
			if (!video && !text && !audio) return toast.error('Informe um conteúdo.');
			if (type === 'EXERCICIO' && !exercisesId) return toast.error('Informe uma lista de exercício.');
			if (!order) return toast.error('Informe a ordem do conteúdo.');

			const response = await ContentAPI.create(payload);

			if (response?.ok) {
				const { contents, count } = await getState();
				contents.push(response.data);

				actions.setData({ type: 'contents', payload: contents });
				actions.setData({ type: 'count', payload: count + 1 });
				toast.success('Conteúdo adicionado com sucesso.');

				return true;
			}
		} catch (e) {
			toast.error(e || errorMessage)
		}
	}),

	createExercisesList: thunk(async (actions, payload) => {
		try {
			const { page = 0, title = '', description = '', order = null, sectionId } = payload;

			if (!title)
				return toast.error('Informe um título para a lista de exercício.');
			if (!order)
				return toast.error('Informe a ordem do conteúdo.');

			const response = await ContentAPI.createExercisesList(payload);
			if (response.ok) {
				actions.get({ page, sectionId });
				toast.success('Lista de exercício criado com sucesso!');
				return true;
			}
			throw response?.data?.message;
		} catch (e) {
			toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
			return false;
		}
	}),

	update: thunk(async (actions, payload, { getState }) => {
		try {
			const {
				id,
				order,
				ObjetoAprendizagem: {
					exercisesId = null,
					type = undefined,
					text = null,
					video = null,
					audio = null
				}
			} = payload;

			if (!id) return toast.error('Informe o ID do conteúdo.');
			if (!order) return toast.error('Informe a ordem do conteúdo.');
			if (!type) return toast.error('Informe o tipo do conteúdo.');
			if (!video && !text && !audio && type !== 'EXERCICIO') return toast.error('Informe um conteúdo.');
			if (type === 'EXERCICIO' && !exercisesId) return toast.error('Informe uma lista de exercício.');

			const response = await ContentAPI.update({
				id,
				order,
				...payload.ObjetoAprendizagem,
			});

			if (response?.ok) {
				const { contents } = await getState();
				let foundedIndex = contents.findIndex(t => t.id === id);
				contents[foundedIndex] = payload;
				actions.setData({ type: 'contents', payload: contents });
				toast.success('Conteúdo atualizado com sucesso.');
				return true;
			}
		} catch (e) {
			return toast.error(e || errorMessage);
		}
	}),

	remove: thunk(async (actions, payload, { getState }) => {
		try {
			const { id = null } = payload;
			if (!id)
				return toast.error('Informe o id do conteúdo que deseja deletar.');

			const response = await ContentAPI.remove(id);
			if (response.ok) {
				const { contents, count } = await getState();
				let foundedIndex = contents.findIndex(t => t.id === id);
				contents.splice(foundedIndex, 1);
				actions.setData({ type: 'contents', payload: contents });
				actions.setData({ type: 'count', payload: count - 1 });

				toast.success('Conteúdo deletado com sucesso!');
				return true;
			}
			throw response?.data?.message;
		} catch (e) {
			toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
			return false;
		}
	}),
}
