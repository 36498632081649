import API from 'api';

const create = (body) => API.post('/exercises-list', body);

const createExercise = (body) => API.post('/exercises-list/exercise', body);

const addExercise = (body) => API.post('/exercises-list/exercise/add', body);

const get = (query = null) => API.get(`/exercises-list?${query ? query : ''}`);

const getDetails = (id) => API.get(`/exercises-list/${id}/details`);

const update = (body) => API.put('/exercises-list', body);

const updateExercise = (body) => API.put('/exercises-list/exercise', body);

const remove = (id) => API.delete(`/exercises-list/${id}`);

const removeExercise = (id) => API.delete(`/exercises-list/exercise/${id}`);

export default {
  create,
  createExercise,
  addExercise,
  get,
  getDetails,
  update,
  updateExercise,
  remove,
  removeExercise,
};