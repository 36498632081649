import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  overflow-x: hidden;

  @media(min-width: 820px) {
    width: 70%;
  }
`