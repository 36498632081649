import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import ChatAPI from 'api/Chat';

export default {
  count: 0,
  chats: [],

  messages: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0 } = payload;
    try {
      let query = 'page=' + page;

      const response = await ChatAPI.get(query);
      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'chats', payload: response.data.data });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  getMessages: thunk(async (actions, payload) => {
    try {
      const { id = null, page = 0 } = payload
      let query = 'page=' + page;
      if (!id) return toast.error('Informe o ID de um chat.');

      const response = await ChatAPI.getMessages(id, query);
      if (response.ok) {
        actions.setData({ type: 'messages', payload: response.data.data });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  sendMessages: thunk(async (actions, payload) => {
    try {
      const { page = 0, teenId = null, message = '', isInstructor = null } = payload;

      if (!isInstructor && !message)
        return toast.error('Informe um usuário para o chat.');

      const response = await ChatAPI.create(payload);
      if (response.ok) {
        actions.getMessages();
        toast.success('Mensagem enviada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  remove: thunk(async (actions, payload) => {
    try {
      const { page = 0, id = null } = payload;
      if (!id)
        return toast.error('Informe o id da mensagem que deseja deletar.');
      const response = await ChatAPI.remove(id);
      if (response.ok) {
        actions.get(page);

        toast.success('Mensagem deletada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  })
};
