import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';

import { Box, OutlinedInput, CircularProgress, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const SchemaValidation = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório'),
  category: Yup.string()
    .required('Campo obrigatório'),
  order: Yup.number().positive('Ordem deve ser maior que 0(zero)!').integer()
    .required('Campo obrigatório'),
  date: Yup.date().nullable(),
  replica: Yup.number().nullable()
});

const ModalCreate = ({ onClose, courseID }) => {
  const [loading, setLoading] = useState(false);
  const [loadingReplica, setLoadingReplica] = useState(false);

  const createClasses = useStoreActions(({ classes }) => classes.create);
  const getClass = useStoreActions(({ classes }) => classes.get);

  const handleSubmit = async ({ name = '', category = '', order = '', date, replica = null }) => {
    if (loading) return;
    setLoading(true);
    const response = await createClasses({
      name,
      category,
      order,
      courseId: parseInt(courseID),
      date,
      replica
    });
    setLoading(false);

    if (response === true)
      onClose();
  };

  const handleCompleteData = async (setFieldValue, replica) => {
    if (!replica || isNaN(replica) || loadingReplica) return;

    setLoadingReplica(true);
    const response = await getClass({ replica });

    console.log('response', response);

    if (response.ok && response?.data && response.data.data && response.data.data.length && response.data.data[0]) {
      console.log('entrei aqui', response.data.data[0].name)
      setFieldValue('name', response.data.data[0].name);
      setFieldValue('category', response.data.data[0].category);
    }

    setLoadingReplica(false);
  };

  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Criar Aula
        </Text>

        <Formik
          initialValues={{
            name: '',
            category: '',
            order: '',
            courseId: '',
            date: null,
            replica: null
          }}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, values, setFieldValue }) => (
            <>
              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Título da aula
              </Text>
              <OutlinedInput
                autoFocus
                autoComplete="off"
                placeholder='Digite o título da aula'
                required
                name="name"
                value={values.name}
                onChange={handleChange('name')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.name && touched.name ? (
                <ErrorText message={errors.name} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Tema da aula
              </Text>
              <OutlinedInput
                placeholder='Digite o tema da aula'
                required
                autoComplete="off"
                name="category"
                value={values.category}
                onChange={handleChange('category')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.category && touched.category ? (
                <ErrorText message={errors.category} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Ordem
              </Text>
              <OutlinedInput
                placeholder='Digite a ordem da aula'
                required
                inputProps={{ min: 0 }}
                type='number'
                name="order"
                value={values.order}
                min="0"
                onChange={handleChange('order')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.order && touched.order ? (
                <ErrorText message={errors.order} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Data
              </Text>
              <OutlinedInput
                type='date'
                name="date"
                value={values.date}
                onChange={handleChange('date')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.date && touched.date ? (
                <ErrorText message={errors.date} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                ID Aula Réplica
              </Text>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item style={{ flex: 1 }}>
                  <OutlinedInput
                    required
                    inputProps={{ min: 0 }}
                    type='number'
                    name="replica"
                    value={values.replica}
                    onChange={handleChange('replica')}
                    sx={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      height: '45px',
                      borderRadius: '4px',
                      width: '95%',
                      backgroundColor: Theme.dark.background,
                      padding: 0,
                      marginTop: 0,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    background={Theme.primary}
                    style={{ marginTop: 0, height: 45 }}
                    disabled={loadingReplica}
                    onClick={() => handleCompleteData(setFieldValue, values.replica)}
                    title="Buscar dados"
                  >
                    {loadingReplica ? <CircularProgress size={16} /> : <SearchIcon style={{ color: '#fff' }} />}
                  </Button>
                </Grid>
              </Grid>

              {errors.replica && touched.replica ? (
                <ErrorText message={errors.replica} />
              ) : null}

              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
                type="submit"
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Criar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </Box>
    </>
  )
};

export default ModalCreate;
