import React from 'react';
import { styled } from '@mui/system';
import InputBase from '@mui/material/InputBase';
import { Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#1C2023',
    border: '1px solid #ced4da',
    fontSize: 19,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#fff',
      boxShadow: '0 0 0 0.2rem rgba(200,200,200,.25)',
    },
  },
}));

const ModalDetails = ({ onClose, selectedLearning }) => {
  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Relatório
        </Text>
        <Grid
          container
          display='flex'
          direction='column'
          style={{ maxHeight: '600px', overflowY: 'scroll', overflowX: 'hidden', padding: 10, color: '#fff', backgroundColor: Theme.dark.secondary,}}
        >
          <Text color={Theme.dark.primaryText} size={16} style={{ marginTop: 25, wordWrap: 'anywhere' }}>
            {selectedLearning.description}
          </Text>
        </Grid>
      </Box>
    </>
  )
}

export default ModalDetails;
