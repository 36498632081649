import React from 'react';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const textContent = {
  display: 'flex',
  flexDirection: 'column',
	maxHeight: '300px',
	overflowY: 'scroll',
	overflowX: 'hidden',
  padding: 10,
  color: '#fff',
  backgroundColor: Theme.dark.secondary,
}

const ModalDetails = ({ onClose, selectedWarning }) => {
  const getYoutubeId = (url = '') => {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
  };

  const createYoutubeLinkEmbed = (video) => {
    const isYoutube = video != null && video.search('www.youtube.com') != -1;

    if (!isYoutube) return video;
    const videoId = getYoutubeId(video);
    return `https://www.youtube.com/embed/${videoId}?fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&playsinline=1&modestbranding=1&enablejsapi=0&hl=pt-br&controls=3`
  };

  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center', maxHeight: '85vh', overflowY: 'scroll' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} size={22} style={{ marginBottom: 35 }}>
          Detalhes
        </Text>
        <Text color={Theme.dark.primaryText} size={18} style={{ marginBottom: 10 }}>
          <b>Título:</b> {selectedWarning?.title}
        </Text>
        <Text color={Theme.dark.primaryText} size={18} style={{ marginBottom: 10 }}>
          <b>Mensagem:</b> {selectedWarning?.message}
        </Text><Text color={Theme.dark.primaryText} size={18} style={{ marginBottom: 10 }}>
          <b>Tipo:</b> {selectedWarning?.type}
        </Text>

        {selectedWarning?.content && (
          <>
            {selectedWarning?.type === 'HTML' && <div style={textContent} dangerouslySetInnerHTML={{ __html: selectedWarning.content }} />}
            {selectedWarning?.type === 'VIDEO' && <iframe src={createYoutubeLinkEmbed(selectedWarning.content)} style={{ minWidth: 300 }} />}
          </>
        )}
      </Box>
    </>
  );
}

export default ModalDetails;
