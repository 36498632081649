import API from 'api';

const create = (body) => API.post('/chat', body);

const get = (query = null) => API.get(`/chat?${query ? query : ''}`);

const getMessages = (id, query = null) => API.get(`/chat/${id}/messages?${query ? query : ''}`);

const remove = (id) => API.delete(`/chat/${id}`);

export default {
  create,
  get,
  getMessages,
  remove,
};