import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Button, CircularProgress, Grid, Modal, Box, Breadcrumbs, Link, Typography } from '@mui/material';

import Text from 'components/Text';
import ModalCreate from './components/ModalCreate';
import Table from './components/Table'

import Theme from 'style/Theme';

const Classes = ({ isAdmin = false }) => {
	const [page, setPage] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [loadClasses, setLoadClasses] = useState(true);
	const { idCourse } = useParams();

	const getClasses = useStoreActions(({ classes }) => classes.get);
	const [count = 0, classes = []] = useStoreState(({ classes }) => [classes.count, classes.classes]);

	const getCourses = useStoreActions(({ course }) => course.get);
	const [course = []] = useStoreState(({ course }) => [course.courses]);

	const openCreateModal = () => {
		setShowModal(true);
	};

	const handleChangePage = async (value) => {
		setPage(value);
		await getClasses({ page: value, courseId: idCourse });
	};

	useEffect(() => {
		async function fetchData() {
			await getClasses({ page: 0, courseId: idCourse });
			await getCourses({ page: 0 });
			setLoadClasses(false);
		}

		fetchData();
	}, [0]);

	const getCourseName = () => {
		const result = course.map(item => (
			item.id === parseInt(idCourse) ? item.name : ''
		))
		return result
	}

	return (
		<>
			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Aulas
					</Text>

					{isAdmin && (
						<Button
							style={{ width: 200, padding: 15, borderRadius: 4, backgroundColor: Theme.dark.primary }}
							onClick={openCreateModal}
						>
							<Text color={Theme.white.primary} size={16}>Nova Aula</Text>
						</Button>
					)}
				</Grid>
			</div>
			<div style={{ padding: 25, paddingBottom: 0 }}>
				<Breadcrumbs aria-label="breadcrumb" color="#fff">
					<Link
						underline="hover"
						color="inherit"
						href="/cursos"
						style={{ color: '#fff' }}
					>
						Cursos
					</Link>

					<Typography color="white">
						{
							getCourseName()
						}
					</Typography>
				</Breadcrumbs>
			</div>

			{loadClasses ? (
				<Grid container justifyContent="center" alignItems="center">
					<CircularProgress style={{ margin: 25 }} />
				</Grid>
			) : (
				<div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background, textAlign: 'center' }}>
					{
						classes.length === 0
							?
							<Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
								Nenhuma Aula para mostrar!
							</Text>
							:
							<Table classes={classes} courseId={idCourse} page={page} handleChangePage={handleChangePage} count={count} isAdmin={isAdmin} />
					}
				</div>
			)}

			{<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
			>
				<ModalCreate
					courseID={idCourse}
					onClose={() => setShowModal(false)}
				/>
			</Modal>}
		</>
	)
};

export default Classes;
