import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';

import { Box, OutlinedInput, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as S from '../../styles';


const SchemaValidation = Yup.object().shape({
  title: Yup.string()
    .required('Campo obrigatório'),
  //description: Yup.string().nullable,
  order: Yup.number().positive('Ordem deve ser maior que 0(zero)!').integer()
    .required('Campo obrigatório'),
});

const ModalCreate = ({ onClose, sectionId, page }) => {
  const [loading, setLoading] = useState(false);

  const createExercisesList = useStoreActions(({ content }) => content.createExercisesList);

  const handleSubmit = async ({ title = '', description = '', order = 1 }) => {
    if (loading) return;
    setLoading(true);
    const response = await createExercisesList({
      title,
      description,
      sectionId,
      order,
      page
    });
    setLoading(false);

    if (response === true)
      onClose();
  };

  return (
    <>
      <S.Box>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Criar Lista de Exercício
        </Text>

        <Formik
          initialValues={{
            title: '',
            //description: '',
            order: 1
          }}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <>
              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Ordem
              </Text>
              <OutlinedInput
                placeholder="Ordem"
                required
                inputProps={{min: 0}}
                type="number"
                name="order"
                value={values.order}
                min="0"
                onChange={handleChange('order')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.order && touched.order ? (
                <ErrorText message={errors.order} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Título da lista
              </Text>
              <OutlinedInput
                autoFocus
                autoComplete="off"
                placeholder='Digite o título da lista'
                required
                name="title"
                value={values.title}
                onChange={handleChange('title')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />

              {errors.title && touched.title ? (
                <ErrorText message={errors.title} />
              ) : null}

              {/* <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Descrição da lista
              </Text>
              <OutlinedInput
                placeholder='Digite a descrição da lista'
                required
                name="description"
                value={values.description}
                onChange={handleChange('description')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              /> */}


              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
                type="submit"
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Criar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </S.Box>
    </>
  )
};

export default ModalCreate;
