import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import Routes from './routes';
import Store from './store';
import * as serviceWorker from './serviceWorker';
import MaterialTheme from './style/MaterialTheme';
import Theme from './style/Theme';


ReactDOM.render(
  <React.StrictMode>
    <Store>
      <Router>
        <MuiThemeProvider theme={MaterialTheme}>
          <ThemeProvider theme={Theme}>
            <ToastContainer />
            <Routes />
          </ThemeProvider>
        </MuiThemeProvider>
      </Router>
    </Store>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
