import React, { useState } from 'react';
import Moment from 'moment';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import Text from 'components/Text';
import ModalUpdate from './components/ModalUpdate';

import Theme from 'style/Theme';

const TableComponent = ({ exercisesList, page = 0, handleChangePage, count = 0 }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedExercisesList, setSelectedExercisesList] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const navigate = useNavigate();

  const deleteExercisesList = useStoreActions(({ exercisesList }) => exercisesList.remove);

  const openDeleteDialog = (exerciseList) => {
    setSelectedExercisesList(exerciseList);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const handleOpenModal = (exerciseList) => {
    setSelectedExercisesList(exerciseList);
    setShowModal(true);
  };

  const handleDeleteExercisesList = async () => {
    if (deleteLoading) return;
    setDeleteLoading(true);
    const response = await deleteExercisesList({ id: selectedExercisesList.id });
    setDeleteLoading(false);

    if (response === true) closeDeleteDialog();
  };

  const goToListExercise = (id) => {
    navigate(`/lista-exercicios/${id}`);
  };

  const orderedExercisesList = exercisesList.sort((a, b) => (a.id > b.id ? 1 : -1))

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalUpdate
          onClose={() => setShowModal(false)}
          selectedExercisesList={selectedExercisesList}
        />
      </Modal>

      <Dialog
        open={showDeleteDialog}
        keepMounted
        onClose={closeDeleteDialog}
        aria-describedby="delete-dialog"
      >
        <DialogTitle>Deletar Exercício</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <b>Lista de Exercício: {selectedExercisesList?.title}</b>
            <br />
            Você realmente deseja deletar essa Lista de Exercício?
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancelar</Button>
          <Button
            onClick={handleDeleteExercisesList}
            disabled={deleteLoading}
            style={{ color: 'red', fontWeight: 'bold' }}>
            {deleteLoading ? <CircularProgress size={20} /> : 'DELETAR'}
          </Button>
        </DialogActions>
      </Dialog>
      {
        exercisesList.length === 0
          ?
          <Text size={20}>
            Nenhum item para mostrar!
          </Text>
          :
          <>

            <TableContainer
              component={Paper}
              style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
            >
              <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Exercises table">
                <TableHead>

                  <TableRow style={{ fontWeight: 'bold' }}>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        ID
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Título
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Descrição
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Criado em
                      </Text>
                    </TableCell>
                    <TableCell align="right">
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Opções
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedExercisesList.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
                    >
                      <TableCell component="th" scope="row" >
                        <Text size={15}>
                          {row.id}
                        </Text>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} onClick={() => goToListExercise(row.id)}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {row.title}
                        </Text>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} onClick={() => goToListExercise(row.id)}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {row.description}
                        </Text>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} onClick={() => goToListExercise(row.id)}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                        </Text>
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          style={{ borderRadius: 4, color: 'white', marginRight: 5 }}
                          onClick={() => handleOpenModal(row)}
                          disabled={deleteLoading}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{ borderRadius: 4, color: 'white' }}
                          onClick={() => openDeleteDialog(row)}
                          disabled={deleteLoading}
                        >
                          {deleteLoading && selectedExercisesList?.id === row.id ? <CircularProgress size={20} /> : <DeleteIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              rowsPerPage={25}
              rowsPerPageOptions={25}
              page={page}
              style={{ color: '#fff' }}
              onPageChange={(e, page) => handleChangePage(page)}
            />
          </>
      }
    </>
  );
};

export default TableComponent;