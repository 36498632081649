import { create } from 'apisauce';
import { toast } from 'react-toastify';

export const API_URL = 'https://api.ijb.edu.br';
//export const API_URL = 'http://localhost:8080';
//export const API_URL = 'http://54.166.23.157:8080';

const API = create({
  baseURL: `${API_URL}/api-cms`,
  headers: {
    'content-type': 'application/json',
    'app-version': 'WEB',
    platform: 'WEB',
    authorization: localStorage.getItem('@AUTH_TOKEN')
  },
  timeout: 30000,
});

export const setToken = async (userToken = '') => {
  let token = userToken;

  if (!userToken) {
    token = (await localStorage.getItem('@AUTH_TOKEN')) || '';
  }

  API.setHeader('authorization', token);
};

setToken(false);

API.addMonitor((response) => {
  if (response.status === 401) {
    localStorage.clear();
    toast('Acesso negado!', { type: 'danger' });
    window.location.href = '/login';
  }
});

export default API;
