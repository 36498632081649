import React, { useState } from 'react';
import Moment from 'moment';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import Text from 'components/Text';
import ModalUpdate from './components/ModalUpdate';

import Theme from 'style/Theme';

const TableComponent = ({ exercises, isAdmin = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const deleteExercises = useStoreActions(({ exercisesList }) => exercisesList.removeExercise);

  const handleOpenModal = (exercise) => {
    setSelectedExercises(exercise);
    setShowModal(true);
  };

  const openDeleteDialog = (exercise) => {
    setSelectedExercises(exercise);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const handleDeleteExercises = async () => {
    if (deleteLoading) return;
    setDeleteLoading(true);
    const response = await deleteExercises({ id: selectedExercises.id });
    setDeleteLoading(false);

    if (response === true) closeDeleteDialog();
  };

  const handleExercisesId = (exerciseId) => {
    navigate(`/exercicio/${exerciseId}/alternativas`);
  };

  const orderedExercises = exercises.sort((a, b) => (a.order > b.order ? 1 : -1))

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalUpdate
          onClose={() => setShowModal(false)}
          exercise={selectedExercises}
        />
      </Modal>

      <Dialog
        open={showDeleteDialog}
        keepMounted
        onClose={closeDeleteDialog}
        aria-describedby="delete-dialog"
      >
        <DialogTitle>Deletar Exercício</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <b>Exercício: {selectedExercises?.title}</b>
            <br />
            Você realmente deseja remover esse Exercício?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancelar</Button>
          <Button
            onClick={handleDeleteExercises}
            disabled={deleteLoading}
            style={{ color: 'red', fontWeight: 'bold' }}>
            {deleteLoading ? <CircularProgress size={20} /> : 'DELETAR'}
          </Button>
        </DialogActions>
      </Dialog>

      <>
        <Text size={18} bold>
          Exercícios:
        </Text>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)', marginTop: 10 }}
        >
          <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Exercises table">
            <TableHead>

              <TableRow style={{ fontWeight: 'bold' }}>
                <TableCell>
                  <Text color={Theme.dark.primaryText} bold size={18}>
                    ID
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={Theme.dark.primaryText} bold size={18}>
                    Enunciado
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={Theme.dark.primaryText} bold size={18}>
                    Ordem
                  </Text>
                </TableCell>
                <TableCell style={{ minWidth: 140 }}>
                  <Text color={Theme.dark.primaryText} bold size={18}>
                    Criado em
                  </Text>
                </TableCell>
                {isAdmin && (
                  <TableCell align="center" style={{ minWidth: 140 }}>
                    <Text color={Theme.dark.primaryText} bold size={18}>
                      Opções
                    </Text>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderedExercises.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
                >
                  <TableCell component="th" scope="row" >
                    <Text size={15} style={{ cursor: 'pointer' }} onClick={() => handleExercisesId(row.exerciseId)}>
                      {row.exerciseId}
                    </Text>
                  </TableCell>
                  <TableCell style={{ cursor: 'pointer' }} onClick={() => handleExercisesId(row.exerciseId)} >
                    <Text color={Theme.dark.primaryText} size={15}>
                      {row.title}
                    </Text>
                  </TableCell>
                  <TableCell style={{ cursor: 'pointer' }} onClick={() => handleExercisesId(row.exerciseId)}>
                    <Text size={15}>
                      {row.order}
                    </Text>
                  </TableCell>
                  <TableCell style={{ cursor: 'pointer' }} onClick={() => handleExercisesId(row.exerciseId)}>
                    <Text color={Theme.dark.primaryText} size={15}>
                      {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                    </Text>
                  </TableCell>

                  {isAdmin && (
                    <TableCell align="right">
                      <Button
                        style={{ borderRadius: 4, color: 'white', marginRight: 5 }}
                        onClick={() => handleOpenModal(row)}
                        disabled={deleteLoading}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        style={{ borderRadius: 4, color: 'white' }}
                        onClick={() => openDeleteDialog(row)}
                        disabled={deleteLoading}
                      >
                        {deleteLoading && selectedExercises?.id === row.id ? <CircularProgress size={20} /> : <DeleteIcon />}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>

    </>
  );
};

export default TableComponent;