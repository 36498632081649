import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import OmbudsmanAPI from 'api/Ombudsman';

export default {
  count: 0,
  messages: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0, message = null } = payload;
    try {
      let query = 'page=' + page;
      if (message) query = query + `&message=${message}`
      const response = await OmbudsmanAPI.get(query);

      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'messages', payload: response.data.data });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  })
};

