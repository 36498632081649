import API from 'api';

const create = (body) => API.post('/course', body);

const get = (query = null) => API.get(`/course?${query ? query : ''}`);

const remove = (id) => API.delete(`/course/${id}`);

const update = (body) => API.put('/course', body);

export default {
  create,
  get,
  remove,
  update,
};
