import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import ClassesAPI from 'api/Classes';

export default {
  count: 0,
  classes: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  create: thunk(async (actions, payload, { getState }) => {
    try {
      const { name = '', category = '', order = null, courseId = null, date, replica } = payload;

      if (!name && !category && !order && !courseId)
        return toast.error('Preencha todos os campos.');

      const response = await ClassesAPI.create(payload);
      if (response.ok) {
        const { classes, count } = await getState();
        classes.push(response.data);
        actions.setData({ type: 'classes', payload: classes });
        actions.setData({ type: 'count', payload: count + 1 });

        toast.success('Aula criada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0, name = null, courseId = null, replica = undefined } = payload;
    try {
      if (!courseId && !replica)
        return toast.error('Informe o ID de um curso.');

      let query = `page=${page}&courseId=${courseId}`;
      if (name) query = query + `&name=${name}`;

      if (replica) query = `id=${replica}`;

      const response = await ClassesAPI.get(query);
      if (response.ok) {
        if (replica) return response;

        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'classes', payload: response.data.data });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  remove: thunk(async (actions, payload, { getState }) => {
    try {
      const { id = null } = payload;
      if (!id)
        return toast.error('Informe o id da aula que deseja deletar.');

      const response = await ClassesAPI.remove(id);
      if (response.ok) {
        const { classes, count } = await getState();
        let foundedIndex = classes.findIndex(t => t.id === id);
        classes.splice(foundedIndex, 1);
        actions.setData({ type: 'classes', payload: classes });
        actions.setData({ type: 'count', payload: count - 1 });

        toast.success('Aula deletada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  update: thunk(async (actions, payload, { getState }) => {
    try {
      const { page = 0, name = '', category = '', order = null, courseId = null, date, id } = payload;

      if (!name && !category)
        return toast.error('Preenha todos os campos.');

      const response = await ClassesAPI.update(payload);
      if (response.ok) {
        const { classes } = await getState();
        let foundedIndex = classes.findIndex(t => t.id === id);
        classes[foundedIndex] = payload;
        actions.setData({ type: 'classes', payload: classes });

        toast.success('Aula atualizada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),
};