import React from 'react';
import { styled } from '@mui/system';
import InputBase from '@mui/material/InputBase';
import { Box, Grid, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const ModalUpdate = ({ onClose, selectedContent }) => {
  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Relatório
        </Text>
        <Grid
          container
          display='flex'
          direction='column'
          style={{ maxHeight: '600px', overflowY: 'scroll', overflowX: 'hidden', padding: 10, color: '#fff', backgroundColor: Theme.dark.secondary,}}
        >
          <Text color={Theme.dark.primaryText} size={16} style={{ marginTop: 25, wordWrap: 'anywhere' }}>
            {selectedContent.description}
          </Text>
        </Grid>
      </Box>
    </>
  )
}

export default ModalUpdate;
