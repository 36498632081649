import API from 'api';

const create = (body) => API.post('/learning-object', body);

const get = (query = null) => API.get(`/learning-object?${query ? query : ''}`);

const update = (body) => API.put('/learning-object', body);

const remove = (id) => API.delete(`/learning-object/${id}`);

export default {
  create,
  get,
  update,
  remove,
};