import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { styled } from '@mui/system';
import InputBase from '@mui/material/InputBase';
import { Box, Checkbox, OutlinedInput, CircularProgress, FormControl, Grid, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#1C2023',
    border: '1px solid #ced4da',
    fontSize: 19,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#fff',
      boxShadow: '0 0 0 0.2rem rgba(200,200,200,.25)',
    },
  },
}));

const SchemaValidation = Yup.object().shape({
  audio: Yup.string().nullable(),
  link: Yup.string().nullable(),
  text: Yup.string().nullable(),
  type: Yup.string().required('Campo obrigatório'),
  hasFeedback: Yup.bool().nullable(),
  video: Yup.string().nullable(),
});

const ModalUpdate = ({ onClose, selectedContent }) => {
  const [loading, setLoading] = useState(false);

  const updateContent = useStoreActions(({ learningObject }) => learningObject.update);

  const handleSubmit = async ({
    type = '',
    video = '',
    link = '',
    audio = '',
    text = '',
    hasFeedback = false,
    exercisesId = null
  }) => {
    if (loading) return;
    if (!type) return toast.error('Informe o tipo do conteúdo.');
    if (!video && !text && !audio) return toast.error('Informe um conteúdo.');

    setLoading(true);
    const response = await updateContent({
      id: selectedContent.id,
      type,
      link,
      video,
      audio,
      text,
      hasFeedback,
      exercisesId
    });
    setLoading(false);

    if (response === true) onClose();
  };

  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Editar Conteúdo
        </Text>

        <Formik
          initialValues={selectedContent}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, setFieldValue, values }) => (
            <>
              <FormControl style={{ width: '100%' }}>
                <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                  Tipo do Conteúdo
                </Text>
                <Select
                  autoFocus
                  labelId="contentLabel"
                  id="contentLabelSelect"
                  value={values.type}
                  label="Tipo do Conteúdo"
                  onChange={(e) => setFieldValue('type', e.target.value)}
                  input={<BootstrapInput />}
                  sx={{
                    color: '#fff',
                  }}
                >
                  <MenuItem value={'TEXTO'}>Texto</MenuItem>
                  <MenuItem value={'VIDEO'}>Vídeo</MenuItem>
                  <MenuItem value={'EXERCICIO'}>Lista de Exercício</MenuItem>
                </Select>
              </FormControl>
              {errors.type && touched.type ? (
                <ErrorText message={errors.type} />
              ) : null}

              <Grid container direction="row" alignItems="center">
                <Checkbox
                  placeholder="Tem avaliação?"
                  label="Tem avaliação?"
                  checked={values.hasFeedback}
                  onChange={() => setFieldValue('hasFeedback', !values.hasFeedback)}
                  style={{ marginLeft: 0, paddingLeft: 0, color: '#fff' }}
                />
                <Text color={Theme.dark.primaryText} bold size={14}>
                  Tem avaliação?
                </Text>
              </Grid>

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginBottom: 5 }}>
                Lista de Exercício (ID)
              </Text>
              <OutlinedInput
                placeholder="Lista de Exercício (ID)"
                inputProps={{min: 0}}
                name="exercisesId"
                value={values.exercisesId}
                type="number"
                onChange={handleChange('exercisesId')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.exercisesId && touched.exercisesId ? (
                <ErrorText message={errors.exercisesId} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Link do Vídeo
              </Text>
              <OutlinedInput
                placeholder="Link do Vídeo"
                autoComplete="off"
                name="video"
                value={values.video}
                onChange={handleChange('video')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.video && touched.video ? (
                <ErrorText message={errors.video} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Link do Áudio
              </Text>
              <OutlinedInput
                placeholder="Link do Áudio"
                autoComplete="off"
                name="audio"
                value={values.audio}
                onChange={handleChange('audio')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.audio && touched.audio ? (
                <ErrorText message={errors.audio} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Texto
              </Text>
              <textarea
                placeholder="Texto"
                name="text"
                value={values.text}
                onChange={handleChange('text')}
                multiline
                minRows={4}
                style={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  minWidth: '300px',
                  minHeight: '200px',
                  borderRadius: '4px',
                  padding: 10,
                  backgroundColor: Theme.dark.background,
                  marginTop: 0,
                  resize: 'vertical',
                  width: '-webkit-fill-available'
                }}
              />
              {errors.text && touched.text ? (
                <ErrorText message={errors.text} />
              ) : null}

              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Atualizar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default ModalUpdate;
