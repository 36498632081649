import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import { setToken } from 'api';
import AuthAPI from 'api/Auth';

export default {
  loading: false,

  setLoading: action((state, payload) => {
    state.loading = !!payload;
  }),

  login: thunk((actions, { email, password }, { getStoreActions }) => {
    if (!email) {
      return toast('Email inválido', { type: 'danger' });
    } else if (!password || password.length < 6) {
      return toast('Senha inválida', { type: 'danger' });
    }

    actions.setLoading(true);

    return AuthAPI.login({ email, password }).then(async (response) => {
      if (response.ok) {
        localStorage.setItem('USER', JSON.stringify(response.data.user));
        await localStorage.setItem('@AUTH_TOKEN', response.data.token);

        setToken(response.data.token);
        const { user } = await getStoreActions();
        user.setUser(response.data.user);
        actions.setLoading(false);
        window.location.href = '/cursos';
        return true;
      }

      let description = response?.data?.message || 'Ocorreu um erro inesperado.';

      toast(description, { type: 'danger' });

      actions.setLoading(false);
      return false;
    });
  }),
};
