import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
	Book,
	Close,
	Feedback,
	Logout,
	RecordVoiceOver,
	Warning,
	School,
	ListAlt,
	YouTube,
	Assessment,
	TrendingUp,
	Chat,
} from '@mui/icons-material';

import LogoWhite from 'assets/images/logo-white.png';

import Text from 'components/Text';
import Theme from 'style/Theme';

const MENU = [
	{
		icon: School,
		title: 'Cursos',
		path: '/cursos',
	},
	// {
	// 	icon: Book,
	// 	title: 'Conteúdos',
	// 	path: '/conteudos',
	// }, {
	// 	icon: RecordVoiceOver,
	// 	title: 'Exercícios',
	// 	path: '/exercicios',
	// },
	// {
	// 	icon: ListAlt,
	// 	title: 'Lista de Exercícios',
	// 	path: '/lista-exercicios',
	// },
	{
		icon: Warning,
		title: 'Avisos',
		path: '/avisos',
	},
	// {
	// 	icon: YouTube,
	// 	title: 'Podcasts',
	// 	path: '/podcasts',
	// },
	{
		icon: Chat,
		title: 'Chat',
		path: '/chat',
		admin: true,
	},
	{
		icon: Assessment,
		title: 'Relatório Geral',
		path: '/relatorio-geral',
		admin: true,
	},
	{
		icon: Assessment,
		title: 'Relatório de atividades práticas',
		path: '/praticas',
		admin: true,
	},
	{
		icon: TrendingUp,
		title: 'Relatórios "O que eu aprendi"',
		path: '/aprendizado',
		admin: true,
	},
	{
		icon: Feedback,
		title: 'Ouvidoria',
		path: '/ouvidoria',
		admin: true,
	},
	{
		icon: Logout,
		title: 'Sair',
		path: '',
	}
]

const DrawerContent = ({ onClose, onLogout, path = '', isAdmin = false }) => {
	const navigate = useNavigate();

	const handleClick = (item) => {
		if (item.title === 'Sair') {
			onClose();
			return onLogout();
		}

		onClose();
		navigate(item.path);
	};

	return (
		<Grid
			container
			direction="column"
			style={{ minWidth: 350, maxWidth: 350, width: '100%', height: '100%', backgroundColor: Theme.dark.secondary, padding: 15, overflowY: 'auto', display: 'flow-root' }}
		>
			<Grid item container justifyContent="flex-end">
				<Close onClick={onClose} style={{ flot: 'right', cursor: 'pointer', color: Theme.dark.primaryText }} title="Fechar" />
			</Grid>
			<Grid item container justifyContent="center">
				<img src={LogoWhite} style={{ width: '80%', marginTop: 10, marginBottom: 15 }} />
			</Grid>

			{
				MENU.map((item, index) => {
					if (isAdmin === false && item?.admin) return null;
					let actived = path === item.path;
					return (
						<div key={`${item.path}-${index}`} style={{ marginTop: 5, padding: 10, backgroundColor: actived ? `${Theme.dark.primary}33` : 'transparent', borderRadius: 8 }}>
							<Grid
								container
								direction="row"
								alignItems="center"
								style={{ cursor: 'pointer' }}
								title={item.title}
								onClick={() => handleClick(item)}
							>
								<item.icon style={{ color: actived ? Theme.dark.primary : Theme.dark.primary }} />
								<Text
									color={actived ? Theme.dark.primary : Theme.dark.primaryText}
									size={15}
									style={{ marginLeft: 15 }}
								>
									{item.title}
								</Text>
							</Grid>
						</div>
					)
				})
			}
		</Grid>
	);
};

export default DrawerContent;
