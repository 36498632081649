import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Text from 'components/Text';
import Theme from 'style/Theme';
import Moment from 'moment';
import { useStoreActions } from 'easy-peasy';

const CardMessage = ({ message, idChat, page, isInstructor }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getMessages = useStoreActions(({ chat }) => chat.getMessages);
  const deleteChat = useStoreActions(({ chat }) => chat.remove);

  const openDeleteDialog = (id) => {
    setSelectedMessage(id);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const handleDeleteChat = async () => {
    if (deleteLoading) return;
    setDeleteLoading(true);
    const response = await deleteChat({ id: message.id });
    setDeleteLoading(false);

    if (response === true) {
      getMessages({ id: idChat, page: page })
    } closeDeleteDialog();

  };

  return (
    <>
      <Dialog
        open={showDeleteDialog}
        keepMounted
        onClose={closeDeleteDialog}
        aria-describedby="delete-dialog"
      >
        <DialogTitle>Deletar Mensagem</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <b>Menssagem: {message?.id}</b>
            <br />
            Você realmente deseja deletar essa mensagem?
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancelar</Button>
          <Button
            onClick={handleDeleteChat}
            disabled={deleteLoading}
            style={{ color: 'red', fontWeight: 'bold' }}>
            {deleteLoading ? <CircularProgress size={20} /> : 'DELETAR'}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        style={{ display: 'flex', flexDirection: 'column' , alignItems: !!isInstructor ? 'end' : 'start'}}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end'}}>
          <div
            style={{
              backgroundColor: !!isInstructor ? Theme.dark.cardMessageInstrutor : Theme.dark.cardMessageStudent,
              borderRadius: !!isInstructor ? '16px 16px 0px 16px' : '16px 16px 16px 0px',
              height: 'auto',
              minWidth: '200px',
              padding: '15px 15px',
              marginTop: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* <Text bold color={Theme.dark.primaryText} style={{ fontSize: '13px', display: 'flex', justifyContent: 'start' }}>Destinatário: {message.addressee}</Text> */}
            <Text color={Theme.dark.primaryText} style={{ fontSize: '15px', display: 'flex', justifyContent: isInstructor === true ? 'end' : 'start'}}>{message.message}</Text>
            <Grid style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
              <Text color={Theme.dark.secondaryText} style={{ fontSize: '12px', textAlign: 'right', marginLeft: '60px', height: '15px' }}>{Moment(message.createdAt).format('HH:mm')}</Text>
            </Grid>

          </div>
          {
            isInstructor && (
              <div>
                <Button
                  sx={{ borderRadius: 4, color: 'white', minWidth: 10, padding: '0px 3px' }}
                  onClick={() => openDeleteDialog(message)}
                  disabled={deleteLoading}
                >
                  {deleteLoading && selectedMessage?.id === message.id ? <CircularProgress size={20} /> : <DeleteIcon />}
                </Button>
              </div>
            )
          }
        </div>
      </Grid>
    </>
  )
};

export default CardMessage;