import auth from './Auth';
import chat from './Chat';
import classes from './Classes';
import content from './Content';
import course from './Course';
import exercises from './Exercises';
import exercisesList from './ExercisesList';
import learning from './Learning';
import learningObject from './LearningObject';
import ombudsman from './Ombudsman';
import practiceReports from './PracticeReports'
import section from './Section';
import user from './User';
import warning from './Warning';
import generalReport from './GeneralReport';

export default {
  auth,
  chat,
  classes,
  content,
  course,
  exercises,
  exercisesList,
  learning,
  learningObject,
  ombudsman,
  practiceReports,
  section,
  user,
  warning,
  generalReport,
};