import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Modal } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Text from 'components/Text';
import ModalCreate from './components/CreateModal';
import Table from './components/Table';

import Theme from 'style/Theme';

const WarningsScreen = ({ isAdmin = false }) => {
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const get = useStoreActions(({ warning }) => warning.get);
  const [count = 0, warnings = []] = useStoreState(({ warning }) => [warning.count, warning.warnings]);

  const openCreateModal = () => {
    setShowModal(true);
  };

  const handleChangePage = async (value) => {
    setPage(value);
    await get({ page: value });
  };

  useEffect(() => {
    async function fetchData() {
      await get({ page: 0 });
      setLoading(false);
    }

    fetchData();
  }, [0]);

  return (
    <>
      <div style={{ paddingTop: 60 }}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
        >
          <Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
            Avisos
          </Text>

          {isAdmin && (
            <Button
              style={{ width: 200, padding: 15, borderRadius: 4, backgroundColor: Theme.dark.primary }}
              onClick={openCreateModal}
            >
              <Text color={Theme.white.primary} size={16}>Novo Aviso</Text>
            </Button>
          )}
        </Grid>
      </div>

      {loading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress style={{ margin: 25 }} />
        </Grid>
      ) : (
        <div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background, textAlign: 'center' }}>
          {
            warnings.length === 0
              ?
              <Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
                Nenhum aviso para mostrar!
              </Text>
              :
              <Table warnings={warnings} page={page} handleChangePage={handleChangePage} count={count} isAdmin={isAdmin} />
          }
        </div>
      )}

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalCreate
          onClose={() => setShowModal(false)}
          page={page}
        />
      </Modal>
    </>
  )
};

export default WarningsScreen;
