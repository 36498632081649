import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: #fff;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const Form = styled.form`
  align-items: center;
  flex: 1;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
`;
