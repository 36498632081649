import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';

import { Box, OutlinedInput, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const SchemaValidation = Yup.object().shape({
  title: Yup.string()
    .required('Campo obrigatório'),
  //description: Yup.string().nullable,
});

const ModalCreate = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const createExercisesList = useStoreActions(({ exercisesList }) => exercisesList.create);

  const handleSubmit = async ({ title = '', description = '' }) => {
    if (loading) return;
    setLoading(true);
    const response = await createExercisesList({
      title,
      description,
    });
    setLoading(false);

    if (response === true)
      onClose();
  };

  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Criar Lista de Exercício
        </Text>

        <Formik
          initialValues={{
            title: '',
            //description: '',
          }}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <>
              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Título da lista
              </Text>
              <OutlinedInput
                autoFocus
                autoComplete="off"
                placeholder='Digite o título da lista'
                required
                name="title"
                value={values.title}
                onChange={handleChange('title')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />

              {errors.title && touched.title ? (
                <ErrorText message={errors.title} />
              ) : null}

              {/* <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Descrição da lista
              </Text>
              <OutlinedInput
                placeholder='Digite a descrição da lista'
                required
                name="description"
                value={values.description}
                onChange={handleChange('description')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              /> */}


              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
                type="submit"
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Criar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </Box>
    </>
  )
};

export default ModalCreate;
