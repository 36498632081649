import React, { useEffect } from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import LoginScreen from 'containers/Auth/Login';
import CoursesScreen from 'containers/Dashboard/Courses';
import ClassesScreen from 'containers/Dashboard/Classes';
import ContentScreen from 'containers/Dashboard/Content';
import SectionsScreen from 'containers/Dashboard/Sections';
import LearningObjectScreen from 'containers/Dashboard/LearningObject';
import ExercisesScreen from 'containers/Dashboard/Exercises';
import ExercisesAlternavitesScreen from 'containers/Dashboard/Alternatives';
import ExercisesListScreen from 'containers/Dashboard/ExercisesList';
import ExercisesListDetailsScreen from 'containers/Dashboard/ExercisesListDetails';
import WarningsScreen from 'containers/Dashboard/Warnings';
import PodcastsScreen from 'containers/Dashboard/Podcasts';
import OmbudsmanScreen from 'containers/Dashboard/Ombudsman';
import PracticeReportsScreen from 'containers/Dashboard/PracticeReports';
import LearningScreen from 'containers/Dashboard/Learning';
import ChatScreen from 'containers/Dashboard/Chat';
import ChatMessageScreen from 'containers/Dashboard/Chat/components/Messages';
import GeneralReportScreen from 'containers/Dashboard/GeneralReport';

import Drawer from 'components/Drawer';

import Theme from 'style/Theme';

const MainRoutes = () => {
  const getMe = useStoreActions(({ user }) => user.getMe);
  const user = useStoreState(({ user }) => user.user);

  const init = async () => {
    const token = localStorage.getItem('@AUTH_TOKEN');

    if (token && !user?.name) {
      getMe();
    }
  };

  useEffect(() => {
    init();
  }, [0]);

  return (
    <div style={{ flex: 1, minHeight: '100vh', width: '100%', backgroundColor: Theme.dark.background }}>
      <Drawer>
        <Routes>
          <Route path='/chat/:idChat/messages' element={<ChatMessageScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/chat' element={<ChatScreen isAdmin={user?.isAdmin || false} />} />

          <Route path='/aprendizado' element={<LearningScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/praticas' element={<PracticeReportsScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/relatorio-geral' element={<GeneralReportScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/ouvidoria' element={<OmbudsmanScreen isAdmin={user?.isAdmin || false} />} />

          <Route path='/lista-exercicios' element={<ExercisesListScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/lista-exercicios/:id' element={<ExercisesListDetailsScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/exercicio/:idExercise/alternativas' element={<ExercisesAlternavitesScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/exercicios' element={<ExercisesScreen isAdmin={user?.isAdmin || false} />} />

          <Route path='/curso/:idCourse/aula/:idClass/secao/:idSection' element={<ContentScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/curso/:idCourse/aula/:idClass' element={<SectionsScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/curso/:idCourse' element={<ClassesScreen isAdmin={user?.isAdmin || false} />} />

          <Route path='/cursos' element={<CoursesScreen isAdmin={user?.isAdmin || false} />} />
          <Route path="/avisos" element={<WarningsScreen isAdmin={user?.isAdmin || false} />} />
          <Route path="/podcasts" element={<PodcastsScreen isAdmin={user?.isAdmin || false} />} />
          <Route path='/conteudos' element={<LearningObjectScreen isAdmin={user?.isAdmin || false} />} />


          <Route path="/" element={<LoginScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="*" element={<LoginScreen />} />
        </Routes>
      </Drawer>
    </div>
  );
};

export default MainRoutes;
