import React from 'react';
import Moment from 'moment';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import Text from 'components/Text';

import Theme from 'style/Theme';

const TableComponent = ({ alternatives }) => {
  const orderedAlternatives = alternatives.sort((a, b) => (a.order > b.order ? 1 : -1))
  return (
    <>
      {
        alternatives.length === 0
          ?
          <Text size={20}>
            Nenhum item para mostrar!
          </Text>
          :
          <>

            <TableContainer
              component={Paper}
              style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
            >
              <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Alternatives table">
                <TableHead>

                  <TableRow style={{ fontWeight: 'bold' }}>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        ID
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Descrição
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Resposta
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Ordem
                      </Text>
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }}>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Criado em
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedAlternatives.map((row, index) => (

                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
                    >

                      <TableCell component="th" scope="row" >
                        <Text size={15}>
                          {row.id}
                        </Text>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} >
                        <Text color={Theme.dark.primaryText} size={15}>
                          <div dangerouslySetInnerHTML={{ __html: row.description }} />
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {row.isCorrect === true ? 'Correto' : 'Falso'}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {row.order}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
      }
    </>
  );
}

export default TableComponent;