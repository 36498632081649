import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Text from 'components/Text';
import Table from './components/Table';

import Theme from 'style/Theme';

const Ombudsman = () => {
	const [page, setPage] = useState(0);
	const [loadOmbudsman, setLoadOmbudsman] = useState(true);

	const getOmbudsman = useStoreActions(({ ombudsman }) => ombudsman.get);
	const [count = 0, ombudsman = []] = useStoreState(({ ombudsman }) => [ombudsman.count, ombudsman.messages]);

	const handleChangePage = async (value) => {
		setPage(value);
		await getOmbudsman({ page: value });
	};

	useEffect(() => {
		async function fetchData() {
			await getOmbudsman({ page: 0 });
			setLoadOmbudsman(false);
		}

		fetchData();
	}, [0]);

	return (
		<>
			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Ouvidoria
					</Text>
				</Grid>
			</div>

			{loadOmbudsman ? (
				<Grid container justifyContent="center" alignItems="center">
					<CircularProgress style={{ margin: 25 }} />
				</Grid>
			) : (
				<div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background, textAlign: 'center' }}>
					{
						ombudsman.length === 0
							?
							<Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
								Nenhum item para mostrar!
							</Text>
							:
							<Table ombudsman={ombudsman} page={page} handleChangePage={handleChangePage} count={count} />
					}
				</div>
			)}
		</>
	)
};

export default Ombudsman;
