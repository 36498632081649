import API from 'api';

const list = (payload) => API.get(`/warnings${payload}`);
const create = (body) => API.post('/warnings', body);
const update = (body) => API.put('/warnings', body);
const remove = (id) => API.delete(`/warnings/${id}`);

export default {
  list,
  create,
  update,
  remove,
};
