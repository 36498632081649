import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import ExercisesListAPI from 'api/ExercisesList';

export default {
  count: 0,
  exercisesList: [],

  exercises: [],
  exercisesListDetails: null,

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  create: thunk(async (actions, payload) => {
    try {
      const { page = 0, title = '', description = '' } = payload;

      if (!title)
        return toast.error('Informe um título e descrição para a lista de exercício.');

      const response = await ExercisesListAPI.create(payload);
      if (response.ok) {
        actions.get(page);

        toast.success('Lista de exercício criado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  createExercise: thunk(async (actions, payload, { getState }) => {
    try {
      const { order = null, title = '', alternatives = [] } = payload;

      if (!title)
        return toast.error('Informe um título para o exercício.');
      if (!alternatives || !alternatives.length || alternatives.length < 5)
        return toast.error('Informe todas as alternativas corretamente.');
      if (!order)
        return toast.error('Informe a ordem do exercício.');

      const { exercisesListDetails } = await getState();
      const response = await ExercisesListAPI.createExercise({
        id: exercisesListDetails.id,
        ...payload
      });
      if (response.ok) {
        actions.getDetails(exercisesListDetails.id);

        toast.success('Exercício criado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  addExercise: thunk(async (actions, payload, { getState }) => {
    try {
      const { id = null, order = null } = payload;

      if (!id || isNaN(id))
        return toast.error('Informe o ID do exercício.');
      if (!order)
        return toast.error('Informe a ordem do exercício.');

      const { exercisesListDetails } = await getState();
      const response = await ExercisesListAPI.addExercise({ id: exercisesListDetails.id, exerciseId: id, order });
      if (response.ok) {
        actions.getDetails(exercisesListDetails.id);

        toast.success('Exercício adicionado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0, title = null } = payload;
    try {
      let query = 'page=' + page;
      if (title) query = query + `&name=${title}`;

      const response = await ExercisesListAPI.get(query);
      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'exercisesList', payload: response.data.data });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  getDetails: thunk(async (actions, id) => {
    if (!id)
      return toast.error('Informe o id da lista de exercício que deseja deletar.');

    try {
      const response = await ExercisesListAPI.getDetails(id);
      if (response.ok) {
        actions.setData({ type: 'exercisesListDetails', payload: response.data.exercisesList });
        actions.setData({ type: 'exercises', payload: response.data.exercises });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  remove: thunk(async (actions, payload) => {
    try {
      const { page = 0, id = null } = payload;
      if (!id)
        return toast.error('Informe o id da lista de exercício que deseja deletar.');

      const response = await ExercisesListAPI.remove(id);
      if (response.ok) {
        actions.get(page);

        toast.success('Lista deletada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  removeExercise: thunk(async (actions, payload, { getState }) => {
    try {
      const { id = null } = payload;
      if (!id)
        return toast.error('Informe o ID do exercício que deseja deletar.');

      const response = await ExercisesListAPI.removeExercise(id);
      if (response.ok) {
        const { exercises } = await getState();
        let foundedIndex = exercises.findIndex(t => t.id === id);
        exercises.splice(foundedIndex, 1);
        actions.setData({ type: 'exercises', payload: exercises });

        toast.success('Exercício deletado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  update: thunk(async (actions, payload) => {
    try {
      const { page = 0, title = '' } = payload;

      if (!title)
        return toast.error('Informe um título para a lista de exercício.');

      const response = await ExercisesListAPI.update(payload);
      if (response.ok) {
        actions.get(page);

        toast.success('Lista de exercício atualizada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  updateExercise: thunk(async (actions, payload, { getState }) => {
    try {
      const { id = null, order = null } = payload;

      if (!order)
        return toast.error('Informe a ordem do exercício.');
      if (!id)
        return toast.error('Informe o ID da lista de exercícios.');

      const response = await ExercisesListAPI.updateExercise(payload);
      if (response.ok) {
        // todo: atualizar variavel
        console.log('response', response.data)
        let { exercises } = await getState();
        let foundedIndex = exercises.findIndex(t => t.id === id);
        exercises[foundedIndex].order = order;
        actions.setData({ type: 'exercises', payload: exercises });

        toast.success('Exercício atualizado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),
};
