import React, { useEffect, useState } from 'react';
import { Breadcrumbs, CircularProgress, Grid, Link, Modal, Typography } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router';

import Text from 'components/Text';
import ModalCreate from './components/ModalCreate';
import ModalCreateExercises from './components/ModalCreateExercises';
import Table from './components/Table';

import Theme from 'style/Theme';
import * as S  from './styles';

const LearningObjectScreen = ({ isAdmin = false }) => {
	const [page, setPage] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [showModalExercise, setShowModalExercise] = useState(false);
	const [laoding, setLoading] = useState(true);

	const { idClass, idCourse, idSection } = useParams();

	const get = useStoreActions(({ content }) => content.get);
	const [count = 0, contents = []] = useStoreState(({ content }) => [content.count, content.contents]);

	const getSections = useStoreActions(({ section }) => section.get);
	const [sections = []] = useStoreState(({ section }) => [section.sections]);

	const getClasses = useStoreActions(({ classes }) => classes.get);
	const [classes = []] = useStoreState(({ classes }) => [classes.classes]);

	const getCourses = useStoreActions(({ course }) => course.get);
	const [course = []] = useStoreState(({ course }) => [course.courses]);

	const openCreateModal = () => {
		setShowModal(true);
	};

	const openCreateModalExercise = () => {
		setShowModalExercise(true);
	};

	const handleChangePage = async (value) => {
		setPage(value);
		await get({ page: value, sectionId: idSection });
	};

	useEffect(() => {
		async function fetchData() {
			await get({ page: 0, sectionId: idSection });
			await getSections({ page: 0, classId: idClass });
			await getCourses({ page: 0 });
			await getClasses({ page: 0, courseId: idCourse });
			setLoading(false);
		};
		fetchData();
	}, [0]);

	const getCourseName = () => {
		const result = course.map(courseItem => (
			courseItem.id === parseInt(idCourse) ? courseItem.name : ''
		))
		return result
	}

	const getClassesName = () => {
		const result = classes.map(classeItem => {
			if (classeItem.replica) {
				return classeItem.replica === parseInt(idClass) ? classeItem.name : ''
			} else {
				return classeItem.id === parseInt(idClass) ? classeItem.name : ''
			}
		})
		return result
	}

	const getSectionName = () => {
		const result = sections.map(sectionItem => (
			sectionItem.id === parseInt(idSection) ? sectionItem.name : ''
		))
		return result
	}

	return (
		<>
			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Conteúdo da seção
					</Text>

					{isAdmin && (
						<S.Grid>
							<S.Button
								onClick={openCreateModal}
							>
								<Text color={Theme.white.primary} size={16}>Novo Conteúdo</Text>
							</S.Button>

							<S.Button
								onClick={openCreateModalExercise}
							>
								<Text color={Theme.white.primary} size={16}>Nova Lista de Exercício</Text>
							</S.Button>
						</S.Grid>
					)}
				</Grid>
			</div>
			<div style={{ padding: 25, paddingBottom: 0 }}>
				<Breadcrumbs aria-label="breadcrumb" color="#fff">
					<Link
						underline="hover"
						color="inherit"
						href="/cursos"
						style={{ color: '#fff' }}
					>
						Cursos
					</Link>
					<Link
						underline="hover"
						color="inherit"
						href={`/curso/${idCourse}`}
						style={{ color: '#fff' }}
					>
						{getCourseName()}
					</Link>
					<Link
						underline="hover"
						color="inherit"
						href={`/curso/${idCourse}/aula/${idClass}`}
						style={{ color: '#fff' }}
					>
						{getClassesName()}
					</Link>
					<Typography color="white">
						{getSectionName()}
					</Typography>
				</Breadcrumbs>
			</div>

			{laoding ? (
				<Grid container justifyContent="center" alignItems="center">
					<CircularProgress style={{ margin: 25 }} />
				</Grid>
			) : (
				<div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background, textAlign: 'center' }}>
					{
						contents.length === 0
							?
							<Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
								Nenhum conteúdo foi encontrado
							</Text>
							:
							<Table content={contents} count={count} page={page} handleChangePage={handleChangePage} isAdmin={isAdmin} />
					}
				</div>
			)}

			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
			>
				<ModalCreate
					onClose={() => setShowModal(false)}
					sectionId={idSection}
				/>
			</Modal>

			<Modal
				open={showModalExercise}
				onClose={() => setShowModalExercise(false)}
			>
				<ModalCreateExercises
					onClose={() => setShowModalExercise(false)}
					sectionId={idSection}
					page={page}
				/>
			</Modal>
		</>
	)
};

export default LearningObjectScreen;
