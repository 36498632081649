import React, { useRef, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Form/Input';
import Text from 'components/Text';

import LogoImage from 'assets/images/logo.png';

import Theme from 'style/Theme';
import * as Styled from './styles';

const LoginSchemaValidation = Yup.object().shape({
	email: Yup.string()
		.required('Campo obrigatório')
		.email('Email inválido'),
	password: Yup.string()
		.min(6, 'Senha muito curta')
		.required('Campo obrigatório'),
});

const initialValues = {
	email: '',
	password: '',
};

const LoginScreen = () => {
	const passwordInputRef = useRef(null);
	let navigate = useNavigate();

	const loading = useStoreState(({ auth }) => auth.loading);
	const loginAction = useStoreActions(({ auth }) => auth.login);

	const checkAlreadyLogged = async () => {
		const token = await localStorage.getItem('@AUTH_TOKEN');
		if (token) navigate('/cursos');
	};

	useEffect(() => {
		checkAlreadyLogged();
	}, [0]);

	const handleSumbit = async ({ email = '', password = '' }) => {
		if (loading) return;

		const response = await loginAction({ email, password });
	};

	return (
		<Grid container direction="row" style={{ flex: 1, backgroundColor: Theme.dark.background }} alignItems="center" justifyContent="center">
			<Grid item style={{ backgroundColor: Theme.dark.background, minHeight: '100vh' }}>
				<Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
					<Grid item style={{ padding: 20 }}>
						<Grid item style={{ textAlign:"center" }}>
							<img src={LogoImage} />
							<Text size={18} color={Theme.dark.primaryText}>
								Seja bem-vindo!
							</Text>

							<Text size={14} color={Theme.dark.grey} align="center" italic style={{ marginTop: 25 }}>
								Entre com o email e senha para acessar o dashboard.
							</Text>
						</Grid>
						<Formik
							initialValues={initialValues}
							validationSchema={LoginSchemaValidation}
							onSubmit={handleSumbit}
						>
							{({ errors, handleChange, handleSubmit, touched, values }) => (
								<Styled.Form onSubmit={handleSumbit}>
									<Text bold color={Theme.dark.secondaryText} size={16}>Email</Text>
									<Input
										value={values.email}
										onChange={handleChange('email')}
										onSubmit={() => passwordInputRef.focus()}
										placeholder="Digite seu email"
										required
									/>
									{errors.email && touched.email ? (
										<ErrorText message={errors.email} />
									) : null}

									<Text bold color={Theme.dark.secondaryText} size={16} style={{ marginTop: 25 }}>Senha</Text>
									<Input
										type="password"
										forwardedRef={passwordInputRef}
										value={values.password}
										onChange={handleChange('password')}
										onSubmit={handleSubmit}
										isPassword
										placeholder="Digite sua senha"
										required
									/>
									{errors.password && touched.password ? (
										<ErrorText message={errors.password} />
									) : null}

									<Button
										background={Theme.dark.primary}
										style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
										height={60}
										disabled={loading}
										onClick={handleSubmit}
										type="submit"
									>
										{loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>ACESSAR</Text>}
									</Button>
								</Styled.Form>
							)}
						</Formik>

					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
};

export default LoginScreen;
