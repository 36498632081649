import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Text from 'components/Text';
import Table from './components/Table';

import Theme from 'style/Theme';

const Learning = () => {
	const [page, setPage] = useState(0);
	const [loadLearning, setLoadLearning] = useState(true);

	const getLearning = useStoreActions(({ learning }) => learning.get);
	const [count = 0, learning = []] = useStoreState(({ learning }) => [learning.count, learning.messages]);

	const handleChangePage = async (value) => {
		setPage(value);
		await getLearning({ page: value });
	};

	useEffect(() => {
		async function fetchData() {
			await getLearning({ page: 0 });
			setLoadLearning(false);
		}

		fetchData();
	}, [0]);

	return (
		<>
			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Relatório &#34;o que aprendi&#34;
					</Text>
				</Grid>
			</div>

			{loadLearning ? (
				<Grid container justifyContent="center" alignItems="center">
					<CircularProgress style={{ margin: 25 }} />
				</Grid>
			) : (
				<div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background, textAlign: 'center' }}>
					{
						learning.length === 0
							?
							<Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
								Nenhum item para mostrar!
							</Text>
							:
							<Table learning={learning} page={page} handleChangePage={handleChangePage} count={count} />
					}
				</div>
			)}
		</>
	)
};

export default Learning;
