import React, { useState } from 'react';
import Moment from 'moment';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

import Text from 'components/Text';
import Theme from 'style/Theme';

const TableComponent = ({ exercises, page = 0, handleChangePage, count = 0 }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const deleteExercises = useStoreActions(({ exercises }) => exercises.remove);

  const openDeleteDialog = (exercise) => {
    setSelectedExercises(exercise);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const handleDeleteExercises = async () => {
    if (deleteLoading) return;
    setDeleteLoading(true);
    const response = await deleteExercises({ id: selectedExercises.id });
    setDeleteLoading(false);

    if (response === true) closeDeleteDialog();
  };

  const handleExercisesId = (exerciseId) => {
    navigate(`/exercicio/${exerciseId}/alternativas`);
  };

  return (
    <>
      <Dialog
        open={showDeleteDialog}
        keepMounted
        onClose={closeDeleteDialog}
        aria-describedby="delete-dialog"
      >
        <DialogTitle>Deletar Exercício</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <b>Exercício: {selectedExercises?.title}</b>
            <br />
            Você realmente deseja deletar essa Exercício?
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancelar</Button>
          <Button
            onClick={handleDeleteExercises}
            disabled={deleteLoading}
            style={{ color: 'red', fontWeight: 'bold' }}>
            {deleteLoading ? <CircularProgress size={20} /> : 'DELETAR'}
          </Button>
        </DialogActions>
      </Dialog>
      {
        exercises.length === 0
          ?
          <Text size={20}>
            Nenhum item para mostrar!
          </Text>
          :
          <>

            <TableContainer
              component={Paper}
              style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
            >
              <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Exercises table">
                <TableHead>

                  <TableRow style={{ fontWeight: 'bold' }}>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        ID
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Enunciado
                      </Text>
                    </TableCell>
                    <TableCell style={{ minWidth: 140 }}>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Criado em
                      </Text>
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 140 }}>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Opções
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exercises.map((row, index) => (

                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
                    >

                      <TableCell component="th" scope="row" >
                        <Text size={15} style={{ cursor: 'pointer' }} onClick={() => handleExercisesId(row.id)}>
                          {row.id}
                        </Text>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} onClick={() => handleExercisesId(row.id)} >
                        <Text color={Theme.dark.primaryText} size={15}>
                          {row.title}
                        </Text>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} onClick={() => handleExercisesId(row.id)}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                        </Text>
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          style={{ borderRadius: 4, color: 'white' }}
                          onClick={() => openDeleteDialog(row)}
                          disabled={deleteLoading}
                        >
                          {deleteLoading && selectedExercises?.id === row.id ? <CircularProgress size={20} /> : <DeleteIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              rowsPerPage={25}
              rowsPerPageOptions={25}
              page={page}
              style={{ color: '#fff' }}
              onPageChange={(e, page) => handleChangePage(page)}
            />
          </>
      }
    </>
  );
};

export default TableComponent;