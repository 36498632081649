import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import CourseAPI from 'api/Course';

export default {
  count: 0,
  courses: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  create: thunk(async (actions, payload) => {
    try {
      const { page = 0, name = '' } = payload;

      if (!name)
        return toast.error('Informe um nome para o curso.');

      const response = await CourseAPI.create(payload);
      if (response.ok) {
        actions.get({ page });

        toast.success('Curso criado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0, name = null } = payload;
    try {
      let query = 'page=' + page;
      if (name) query = query + `&name=${name}`;

      const response = await CourseAPI.get(query);
      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'courses', payload: response.data.data });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  remove: thunk(async (actions, payload) => {
    try {
      const { page = 0, id = null } = payload;
      if (!id)
        return toast.error('Informe o id do curso que deseja deletar.');

      const response = await CourseAPI.remove(id);
      if (response.ok) {
        actions.get({ page });

        toast.success('Curso deletado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  update: thunk(async (actions, payload) => {
    try {
      const { page = 0, name = '' } = payload;

      if (!name)
        return toast.error('Informe um nome para o curso.');

      const response = await CourseAPI.update(payload);
      if (response.ok) {
        actions.get({ page });

        toast.success('Curso atualizado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),
};
