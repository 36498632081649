import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import GeneralReport from 'api/GeneralReport';

export default {
  count: 0,
  generalReport: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  get: thunk(async (actions, payload) => {
    try {
      const { page = 0 } = payload;
      const response = await GeneralReport.get(page);
      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'generalReport', payload: response.data.data });
        return true;
      }
      throw response?.data?.report;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  
  getCsv: thunk(async (actions) => {
    try {
      const response = await GeneralReport.getCsv();
      if (response.ok) {
        return response.data;
      }
      throw response?.data?.report;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

}