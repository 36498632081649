import React, { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Box, OutlinedInput, InputBase, FormControl, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 5,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#1C2023',
    border: '1px solid #ced4da',
    fontSize: 19,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#fff',
      boxShadow: '0 0 0 0.2rem rgba(200,200,200,.25)',
    },
  },
}));

const SchemaValidation = Yup.object().shape({
  title: Yup.string().required('campo obrigatório'),
  text: Yup.string().nullable(),
  video: Yup.string().nullable(),
  type: Yup.string().nullable(),
  highlight: Yup.string().nullable(),
  sendPush: Yup.bool()
});

const CreateWarningModal = ({ onClose, page }) => {
  const [loading, setLoading] = useState(null);

  const create = useStoreActions(({ warning }) => warning.create);

  const handleSubmit = async ({
    title,
    text,
    html,
    video,
    type,
    highlight,
    sendPush = false,
  }) => {
    if (loading) return;
    if (!title) return toast.error('Informe um título.');
    if (!text && !video && !type) return toast.error('Informe ao menos um conteúdo.');

    let content;

    if (type === 'VIDEO') content = video;
    if (type === 'HTML') content = html;

    setLoading(true);
    const response = await create({ title, message: text, content, type, highlight, page, sendPush });
    setLoading(false);
    if (response === true) onClose();
  }

  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <div>
          <Text color={Theme.dark.primary} bold size={18}>
            Criar Novo Aviso
          </Text>
        </div>

        <Formik
          initialValues={{
            title: '',
            html: '',
            text: '',
            video: '',
            type: 'TEXTO',
            highlight: false,
            sendPush: false,
          }}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, values, setFieldValue }) => (
            <>
              <FormControl style={{ width: '100%' }}>
                <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                  Tipo do conteúdo
                </Text>
                <Select
                  autoFocus
                  labelId="warningContentLabel"
                  id="warningContentLabelSelect"
                  name="type"
                  value={values.type}
                  label="Tipo do Conteúdo"
                  onChange={(e) => setFieldValue('type', e.target.value)}
                  input={<BootstrapInput />}
                  sx={{
                    color: '#fff',
                  }}
                >
                  <MenuItem value={'TEXTO'}>Texto</MenuItem>
                  <MenuItem value={'VIDEO'}>Vídeo</MenuItem>
                  <MenuItem value={'HTML'}>HTML</MenuItem>
                </Select>
              </FormControl>
              {errors.type && touched.type ? (
                <ErrorText message={errors.type} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Título
              </Text>
              <OutlinedInput
                placeholder="Título"
                autoComplete="off"
                name="title"
                value={values.title}
                onChange={handleChange('title')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.video && touched.video ? (
                <ErrorText message={errors.title} />
              ) : null}

              {values.type === 'VIDEO' ? (
                <>
                  <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                    Vídeo
                  </Text>

                  <OutlinedInput
                    placeholder="link do vídeo"
                    autoComplete="off"
                    name="video"
                    value={values.video}
                    onChange={handleChange('video')}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      width: '100%',
                      height: '45px',
                      borderRadius: '4px',
                      backgroundColor: Theme.dark.background,
                      padding: 0,
                      marginTop: 0,
                    }}
                  />
                </>
              ) : null}
              {errors.text && touched.text ? (
                <ErrorText message={errors.video} />
              ) : null}

              {values.type === 'HTML' &&
                <>
                  <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                    HTML
                  </Text>
                  <textarea
                    placeholder="HTML"
                    name="html"
                    value={values.html}
                    onChange={handleChange('html')}
                    multiline="true"
                    minRows={4}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      minWidth: '300px',
                      minHeight: '65px',
                      borderRadius: '4px',
                      padding: 10,
                      backgroundColor: Theme.dark.background,
                      marginTop: 0,
                      resize: 'vertical',
                      width: '-webkit-fill-available'
                    }}
                  />
                </>
              }
              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Texto
              </Text>
              <textarea
                placeholder="Digite o Texto"
                name="text"
                value={values.text}
                onChange={handleChange('text')}
                multiline="true"
                minRows={4}
                style={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  minWidth: '300px',
                  minHeight: '65px',
                  borderRadius: '4px',
                  padding: 10,
                  backgroundColor: Theme.dark.background,
                  marginTop: 0,
                  resize: 'vertical',
                  width: '-webkit-fill-available'
                }}
              />
              {errors.text && touched.text ? (
                <ErrorText message={errors.text} />
              ) : null}

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 10, marginTop: 10 }}>
                <Checkbox
                  style={{ color: '#fff' }}
                  name='sendPush'
                  value={values.sendPush}
                  onChange={() => setFieldValue('sendPush', !values.sendPush)}
                />
                <span style={{ marginLeft: 5, color: '#fff' }}>Enviar por notificação</span>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 10, marginTop: 10 }}>
                <Checkbox
                  style={{ color: '#fff' }}
                  name='highlight'
                  value={values.highlight}
                  onChange={() => setFieldValue('highlight', !values.highlight)}
                />
                <span style={{ marginLeft: 5, color: '#fff' }}>Destacar</span>
              </div>

              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
                type="submit"
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Criar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default CreateWarningModal;
