import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import ExercisesAPI from 'api/Exercises';

export default {
  count: 0,
  exercises: [],

  alternatives: [],
  exercise: null,

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  create: thunk(async (actions, payload) => {
    try {
      const { page = 0, title = '', alternatives = [] } = payload;

      if (!title)
        return toast.error('Informe um título para o exercício.');
      if (!alternatives || !alternatives.length || alternatives.length < 5)
        return toast.error('Informe todas as alternativas corretamente.');

      const response = await ExercisesAPI.create(payload);
      if (response.ok) {
        actions.get(page);

        toast.success('Exercício criado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0, title = null } = payload;
    try {
      let query = 'page=' + page;
      if (title) query = query + `&name=${title}`;

      const response = await ExercisesAPI.get(query);
      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'exercises', payload: response.data.data });
        return true;
      }

      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  getAlternatives: thunk(async (actions, id) => {
    try {
      if (!id) return toast.error('Informe o ID de um exercício.');

      const response = await ExercisesAPI.getAlternatives(id);
      if (response.ok) {
        actions.setData({ type: 'alternatives', payload: response.data.alternatives });
        actions.setData({ type: 'exercise', payload: response.data.exercise });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  remove: thunk(async (actions, payload) => {
    try {
      const { page = 0, id = null } = payload;
      if (!id)
        return toast.error('Informe o id do exercício que deseja deletar.');

      const response = await ExercisesAPI.remove(id);
      if (response.ok) {
        actions.get(page);

        toast.success('Exercício deletado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  update: thunk(async (actions, payload) => {
    try {
      const { page = 0, title = '', alternatives = [], id } = payload;

      if (!title)
        return toast.error('Informe um título para o exercício.');
      if (!alternatives || !alternatives.length || alternatives.length < 5)
        return toast.error('Informe todas as alternativas corretamente.');

      const response = await ExercisesAPI.update(payload);
      if (response.ok) {
        actions.getAlternatives(id);

        toast.success('Exercício atualizado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),
};
