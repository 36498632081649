import React, { useState } from 'react';
import Moment from 'moment';
import { useStoreActions } from 'easy-peasy';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, PlayArrow } from '@mui/icons-material';

import Text from 'components/Text';
import ModalUpdate from './components/ModalUpdate';
import ModalDetails from './components/ModalDetails';

import Theme from 'style/Theme';

const TableComponent = ({ contents, page = 0, handleChangePage, count = 0, isAdmin = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedWarning, setSelectedWarning] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteWarning = useStoreActions(({ warning }) => warning.remove);

  const openDeleteDialog = (warning) => {
    setSelectedWarning(warning);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const handleOpenModal = (warning) => {
    setSelectedWarning(warning);
    setShowModal(true);
  };

  const handleDelete = async () => {
    if (deleteLoading) return;
    setDeleteLoading(true);
    const response = await deleteWarning({ id: selectedWarning.id, page });
    setDeleteLoading(false);

    if (response === true) closeDeleteDialog();
  };

  const handleOpenDetails = (warning) => {
    setSelectedWarning(warning);
    setShowDetailsModal(true);
  };
  
  const orderedWarnings = contents.sort((a, b) => (a.id > b.id ? 1 : -1));

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalUpdate
          onClose={() => setShowModal(false)}
          selectedWarning={selectedWarning}
        />
      </Modal>

      <Modal
        open={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
      >
        <ModalDetails
          onClose={() => setShowDetailsModal(false)}
          selectedWarning={selectedWarning}
        />
      </Modal>

      <Dialog
        open={showDeleteDialog}
        keepMounted
        onClose={closeDeleteDialog}
        aria-describedby="delete-dialog"
      >
        <DialogTitle>Deletar Aviso</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <b>Aviso: {selectedWarning?.title}</b>
            <br />
            Você realmente deseja deletar esse aviso?
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancelar</Button>
          <Button
            onClick={handleDelete}
            disabled={deleteLoading}
            style={{ color: 'red', fontWeight: 'bold' }}>
            {deleteLoading ? <CircularProgress size={20} /> : 'DELETAR'}
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer
        component={Paper}
        style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
      >
        <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Courses table">
          <TableHead>
            <TableRow style={{ fontWeight: 'bold' }}>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  ID
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Título
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Tipo
                </Text>
              </TableCell>
              <TableCell style={{ minWidth: 130 }}>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Data Início
                </Text>
              </TableCell>
              <TableCell style={{ minWidth: 130 }}>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Data Fim
                </Text>
              </TableCell>
              {isAdmin && (
                <TableCell align="right" style={{ minWidth: 140 }}>
                  <Text color={Theme.dark.primaryText} bold size={18}>
                    Opções
                  </Text>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedWarnings.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
              >
                <TableCell component="th" scope="row">
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.id}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row?.video.length > 140 ? `${row?.video.substring(0, 140)}[...]` : row?.video}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.type}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {Moment(row.start).format('DD/MM/YYYY')}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {Moment(row.end).format('DD/MM/YYYY')}
                  </Text>
                </TableCell>
                
                <TableCell align="right">
                  <Button
                    style={{ borderRadius: 4, color: 'white', marginRight: 5 }}
                    onClick={() => handleOpenDetails(row)}
                    disabled={deleteLoading}
                  >
                    <PlayArrow />
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        style={{ borderRadius: 4, color: 'white', marginRight: 5 }}
                        onClick={() => handleOpenModal(row)}
                        disabled={deleteLoading}
                      >
                        <EditIcon />
                      </Button>

                      <Button
                        style={{ borderRadius: 4, color: 'white' }}
                        onClick={() => openDeleteDialog(row)}
                        disabled={deleteLoading}
                      >
                        {deleteLoading && selectedWarning?.id === row.id ? <CircularProgress size={20} /> : <DeleteIcon />}
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={25}
        rowsPerPageOptions={25}
        page={page}
        style={{ color: '#fff' }}
        onPageChange={(e, page) => handleChangePage(page)}
      />
    </>
  );
};

export default TableComponent;