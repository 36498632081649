import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import PracticeReports from 'api/PracticeReports';

export default {
  count: 0,
  practiceReports: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0, report = null } = payload;
    try {
      let query = 'page=' + page;
      if (report) query = query + `&description=${report}`;

      const response = await PracticeReports.get(query);
      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'practiceReports', payload: response.data.data });
        return true;
      }
      throw response?.data?.report;
    } catch (e) {
      toast.error(e || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),
}