import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';

import { Box, OutlinedInput, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const SchemaValidation = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório'),
  order: Yup.number().positive('Ordem deve ser maior que 0(zero)!').integer()
    .required('Campo obrigatório'),
});

const ModalCreate = ({ onClose, classID }) => {
  const [loading, setLoading] = useState(false);

  const createSections = useStoreActions(({ section }) => section.create);

  const handleSubmit = async ({ name = '', order = '' }) => {
    if (loading) return;
    setLoading(true);
    const response = await createSections({
      name,
      order,
      classId: parseInt(classID)
    });
    setLoading(false);

    if (response === true)
      onClose();
  };

  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Criar Seção
        </Text>

        <Formik
          initialValues={{
            name: '',
            order: '',
            classId: '',
          }}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <>
              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Nome da Seção
              </Text>
              <OutlinedInput
                autoFocus
                autoComplete="off"
                placeholder='Digite o nome da seção'
                name="name"
                value={values.name}
                onChange={handleChange('name')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.name && touched.name ? (
                <ErrorText message={errors.name} />
              ) : null}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Ordem
              </Text>
              <OutlinedInput
                placeholder='Digite a ordem da seção'
                required
                inputProps={{min: 0}}
                type='number'
                name="order"
                value={values.order}
                min="0"
                onChange={handleChange('order')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />

              {errors.order && touched.order ? (
                <ErrorText message={errors.order} />
              ) : null}

              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
                type="submit"
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Criar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </Box>
    </>
  )
};

export default ModalCreate;
