import API from 'api';

const create = (body) => API.post('/content', body);
const createExercisesList = (body) => API.post('/content/exercises', body);
const get = (query = null) => API.get(`/content?${query ? query : ''}`);
const update = (body) => API.put('/content', body);
const remove = (id) => API.delete(`/content/${id}`);
const list = (query = null) => API.get(`/learning-object?type=PODCAST`);


export default {
    create,
    createExercisesList,
    get,
    update,
    remove,
    list,
};