import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
	Drawer,
	Toolbar,
	Grid,
	Menu,
	MenuItem,
	Button,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
import { useStoreState } from 'easy-peasy';

import { AccountCircle, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import LogoWhite from 'assets/images/logo-white.png';
import Theme from 'style/Theme';

import Text from 'components/Text';
import DrawerContent from './components/DrawerContent';

const MainDrawer = ({ children }) => {
	const [open, setOpen] = React.useState(false);

	const user = useStoreState(({ user }) => user.user);

	const location = useLocation();

	const [dialogLogout, setDialogLogout] = React.useState(false);

	const handleOpenDialogLogout = () => setDialogLogout(true);
	const handleCloseDialogLogout = () => setDialogLogout(false);

	const handleDrawerOpen = () => setOpen(true)

	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleMenu = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const handleLogout = () => {
		handleOpenDialogLogout();
	};

	const logout = () => {
		handleClose();
		handleCloseDialogLogout();

		localStorage.clear();
		window.location.replace('/');
	};

	if (location.pathname === '/login' || location.pathname === '/') return children;

	return (
		<>
			<Dialog
				open={dialogLogout}
				keepMounted
				onClose={handleCloseDialogLogout}
				aria-describedby="logout-dialog"
			>
				<DialogTitle>Sair</DialogTitle>
				<DialogContent>
					<DialogContentText id="logout-dialog-description">
						Você realmente deseja sair?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialogLogout}>Cancelar</Button>
					<Button onClick={logout}>Sair</Button>
				</DialogActions>
			</Dialog>

			<Toolbar style={{
				backgroundColor: Theme.dark.tertiary,
				position: 'fixed',
				width: '100%',
				zIndex: 1000
			}}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<div>
						<Grid container direction="row" alignItems="center" marginLeft={'10%'}>
							<IconButton
								onClick={handleDrawerOpen}
								edge="start"
								sx={{ mr: 2, color: Theme.dark.primaryText }}
							>
								<MenuIcon />
							</IconButton>
							<img src={LogoWhite} style={{ height: 35 }} />
						</Grid>
					</div>

					<Button
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						style={{ marginRight: '5%', color: Theme.dark.primaryText }}
					>
						<AccountCircle style={{ color: Theme.dark.primaryText }} />
						<Text color={Theme.dark.primaryText} size={14} style={{ marginLeft: 7, marginRight: 2 }}>
							{user?.email}
						</Text>
						<ExpandMore style={{ color: Theme.dark.primaryText }} />
					</Button>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={!!anchorEl}
						onClose={handleClose}
					>
						<MenuItem onClick={() => { handleClose(); handleLogout(); }}>Sair</MenuItem>
					</Menu>
				</Grid>
			</Toolbar>

			<Drawer
				variant="temporary"
				anchor="left"
				PaperProps={{ backgroundColor: Theme.dark.secondary }}
				open={open}
				onBackdropClick={() => setOpen(false)}
			>
				<DrawerContent
					path={location.pathname}
					onClose={() => setOpen(false)}
					onLogout={handleOpenDialogLogout}
					isAdmin={user?.isAdmin || false}
				/>
			</Drawer>
			{children}
		</>
	);

};

export default MainDrawer;