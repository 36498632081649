import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { createBrowserHistory } from 'history';

import { Box, Breadcrumbs, CircularProgress, Grid, Link, Modal, Typography, } from '@mui/material';

import Text from 'components/Text';
import ModalUpdate from './components/ModalUpdate';
import Table from './components/Table';

import Theme from 'style/Theme';
import Button from 'components/Button';

const Alternatives = ({ isAdmin = false }) => {
	const [showModal, setShowModal] = useState(false);
	const [loadAlternatives, setLoadAlternatives] = useState(true);
	const { idExercise } = useParams();
	const history = createBrowserHistory();

	const getAlternatives = useStoreActions(({ exercises }) => exercises.getAlternatives);
	const [alternatives = [], exercise = {}] = useStoreState(({ exercises }) => [exercises.alternatives, exercises.exercise]);

	const handleOpenModal = () => {
		setShowModal(true);
	};

	useEffect(() => {
		async function fetchData() {
			await getAlternatives(idExercise);
			setLoadAlternatives(false);
		}

		fetchData();
	}, [0]);

	return (
		<>
			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
			>
				<ModalUpdate
					onClose={() => setShowModal(false)}
					exercise={exercise}
					alternatives={alternatives}
				/>
			</Modal>

			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Alternativas
					</Text>

					{isAdmin && (
						<Button
							style={{ width: 200, padding: 15, borderRadius: 4, backgroundColor: Theme.dark.primary }}
							onClick={handleOpenModal}
						>
							<Text color={Theme.white.primary} size={16}>Editar</Text>
						</Button>
					)}
				</Grid>
			</div>

			<div style={{ padding: 25, paddingBottom: 0 }}>
				<Breadcrumbs aria-label="breadcrumb" color="#fff">
					<Link
						underline="hover"
						color="inherit"
						style={{ color: '#fff', cursor: 'pointer'}}
            onClick={() => history.back()}
					>
						Lista de exercícios - Detalhes
					</Link>
				</Breadcrumbs>
			</div>

			{loadAlternatives ? (
				<Grid container justifyContent="center" alignItems="center">
					<CircularProgress style={{ margin: 25 }} />
				</Grid>
			) : (
				<div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background, textAlign: 'center' }}>
					<Box style={{ padding: 20, backgroundColor: Theme.dark.secondary, marginBottom: 25, borderRadius: 5, color: '#fff' }}>
						<div dangerouslySetInnerHTML={{ __html: exercise?.title }} />
					</Box>
					{
						alternatives.length === 0
							?
							<Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
								Nenhuma item para mostrar!
							</Text>
							:
							<Table alternatives={alternatives} />
					}
				</div>
			)
			}
		</>
	)
};

export default Alternatives;
