import React, { useState } from 'react';
import Moment from 'moment';

import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import Text from 'components/Text';
import ModalDetails from '../ModalDetails';

import Theme from 'style/Theme';

const TableComponent = ({ ombudsman, page = 0, handleChangePage, count = 0 }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const openModal = (content) => {
    setSelectedContent(content);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalDetails onClose={closeModal} selectedContent={selectedContent} />
      </Modal>

      <TableContainer
        component={Paper}
        style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
      >
        <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Ombudsman table">
          <TableHead>
            <TableRow style={{ fontWeight: 'bold' }}>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  ID
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Jovem
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Curso
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Mensagem
                </Text>
              </TableCell>
              <TableCell style={{ minWidth: 140, cursor: 'pointer' }}>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Criado em
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ombudsman.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
              >
                <TableCell component="th" scope="row" style={{ cursor: 'pointer' }} onClick={() => openModal(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.id}
                  </Text>
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={() => openModal(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.name}
                  </Text>
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={() => openModal(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.course}
                  </Text>
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={() => openModal(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row?.message.length > 50 ? `${row.message.substring(0, 50)}[...]` : row?.message}
                  </Text>
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={() => openModal(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={25}
        rowsPerPageOptions={25}
        page={page}
        style={{ color: '#fff' }}
        onPageChange={(e, page) => handleChangePage(page)}
      />
    </>
  );
};

export default TableComponent;