import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import SectionAPI from 'api/Section'

export default {
  count: 0,
  sections: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  create: thunk(async (actions, payload, { getState }) => {
    try {
      const { name = '', order } = payload;

      if (!name && !order)
        return toast.error('Informe um nome e ordem para a sessão.');

      const response = await SectionAPI.create(payload);
      if (response.ok) {
        const { sections, count } = await getState();
        sections.push(response.data);
        actions.setData({ type: 'sections', payload: sections });
        actions.setData({ type: 'count', payload: count + 1 });

        toast.success('Sessão criada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  get: thunk(async (actions, payload) => {
    const { page = 0, name = null, classId } = payload;
    try {
      if (!classId) return toast.error('Informe o ID da seção.');

      let query = `page=${page}&classId=${classId}`;
      if (name) query = query + `&name=${name}`;

      const response = await SectionAPI.get(query);
      if (response.ok) {
        actions.setData({ type: 'count', payload: response.data.count });
        actions.setData({ type: 'sections', payload: response.data.data });
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  remove: thunk(async (actions, payload, { getState }) => {
    try {
      const { id = null } = payload;
      if (!id)
        return toast.error('Informe o ID da sessão que deseja deletar.');

      const response = await SectionAPI.remove(id);
      if (response.ok) {
        const { sections, count } = await getState();
        let foundedIndex = sections.findIndex(t => t.id === id);
        sections.splice(foundedIndex, 1);
        actions.setData({ type: 'sections', payload: sections });
        actions.setData({ type: 'count', payload: count - 1 });

        toast.success('Sessão deletada com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),

  update: thunk(async (actions, payload, { getState }) => {
    try {
      const { name = '', id } = payload;

      if (!name)
        return toast.error('Informe um nome para a sessão.');

      const response = await SectionAPI.update(payload);
      if (response.ok) {
        const { sections } = await getState();
        let foundedIndex = sections.findIndex(t => t.id === id);
        sections[foundedIndex] = payload;
        actions.setData({ type: 'sections', payload: sections });

        toast.success('Sessão atualizado com sucesso!');
        return true;
      }
      throw response?.data?.message;
    } catch (e) {
      toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
      return false;
    }
  }),
};