import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { styled } from '@mui/system';
import InputBase from '@mui/material/InputBase';
import { Box, Checkbox, OutlinedInput, CircularProgress, FormControl, Grid, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as S from '../../styles';

const SchemaValidation = Yup.object().shape({
  audio: Yup.string(),
  text: Yup.string(),
  type: Yup.string().required('Campo obrigatório'),
  order: Yup.number().positive('Ordem deve ser maior que 0(zero)!').integer()
    .required('Campo obrigatório'),
  //exercisesId: Yup.number(),
  hasFeedback: Yup.bool(),
  video: Yup.string(),
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#1C2023',
    border: '1px solid #ced4da',
    fontSize: 19,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'white',
      boxShadow: '0 0 0 0.2rem rgba(200,200,200,.25)',
    },
  },
}));

const ModalCreate = ({ onClose, sectionId }) => {
  const [loading, setLoading] = useState(false);

  const create = useStoreActions(({ content }) => content.create);

  const handleSubmit = async ({
    type = '',
    video = '',
    audio = '',
    text = '',
    order = null,
    hasFeedback = false,
    exercisesId = null
  }) => {
    if (loading) return;
    if (!type) return toast.error('Informe o tipo do conteúdo.');
    if (!order) return toast.error('Informe a ordem do conteúdo.');
    if (!video && !text && !audio) return toast.error('Informe um conteúdo.');

    setLoading(true);
    const response = await create({
      order,
      sectionId,
      type,
      video: type === 'VIDEO' ? video : null,
      audio,
      text: type === 'TEXTO' ? text : null,
      hasFeedback: type === 'VIDEO' ? hasFeedback : false,
      exercisesId: type === 'EXERCICIO' ? exercisesId : null,
    });
    setLoading(false);

    if (response === true) onClose();
  };

  return (
    <>
      <S.Box>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Criar Conteúdo
        </Text>

        <Formik
          initialValues={{
            type: null,
            video: '',
            audio: '',
            text: '',
            hasFeedback: false,
            exercisesId: null,
            order: null
          }}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, setFieldValue, values }) => (
            <>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item style={{ width: '25%' }}>
                  <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                    Ordem
                  </Text>
                  <OutlinedInput
                    placeholder="Ordem"
                    required
                    inputProps={{min: 0}}
                    type="number"
                    name="order"
                    value={values.order}
                    min="0"
                    onChange={handleChange('order')}
                    sx={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      width: '100%',
                      height: '52px',
                      borderRadius: '4px',
                      backgroundColor: Theme.dark.background,
                      padding: 0,
                      marginTop: 0,
                    }}
                  />
                  {errors.order && touched.order ? (
                    <ErrorText message={errors.order} />
                  ) : null}
                </Grid>

                <Grid item style={{ width: '70%' }}>
                  <FormControl style={{ width: '100%' }}>
                    <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                      Tipo do Conteúdo
                    </Text>
                    <Select
                      autoFocus
                      labelId="contentLabel"
                      id="contentLabelSelect"
                      value={values.type}
                      label="Tipo do Conteúdo"
                      onChange={(e) => setFieldValue('type', e.target.value)}
                      input={<BootstrapInput />}
                      sx={{color: 'white',}}
                    >
                      <MenuItem value={'TEXTO'}>Texto</MenuItem>
                      <MenuItem value={'VIDEO'}>Vídeo</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.type && touched.type ? (
                    <ErrorText message={errors.type} />
                  ) : null}
                </Grid>
              </Grid>

              {values.type === 'VIDEO' && (
                <>
                  <Grid item container direction="row" alignItems="center" justifyContent="start" style={{ marginTop: 10 }}>
                    <Checkbox
                      placeholder="Tem avaliação?"
                      label="Tem avaliação?"
                      checked={values.hasFeedback}
                      onChange={() => setFieldValue('hasFeedback', !values.hasFeedback)}
                      style={{ marginLeft: 0, paddingLeft: 0, color: 'white' }}
                      disabled={values.type !== 'VIDEO'}

                    />
                    <Text color={Theme.dark.primaryText} bold size={14}>
                      Tem avaliação?
                    </Text>
                  </Grid>

                  <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                    Link do Vídeo
                  </Text>
                  <OutlinedInput
                    autoComplete="off"
                    placeholder="Link do Vídeo"
                    name="video"
                    value={values.video}
                    onChange={handleChange('video')}
                    sx={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      width: '100%',
                      height: '45px',
                      borderRadius: '4px',
                      backgroundColor: Theme.dark.background,
                      padding: 0,
                      marginTop: 0,
                    }}
                  />
                  {errors.video && touched.video ? (
                    <ErrorText message={errors.video} />
                  ) : null}
                </>
              )}

              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Link do Áudio
              </Text>
              <OutlinedInput
                autoComplete="off"
                placeholder="Link do Áudio"
                name="audio"
                value={values.audio}
                onChange={handleChange('audio')}
                sx={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  width: '100%',
                  height: '45px',
                  borderRadius: '4px',
                  backgroundColor: Theme.dark.background,
                  padding: 0,
                  marginTop: 0,
                }}
              />
              {errors.audio && touched.audio ? (
                <ErrorText message={errors.audio} />
              ) : null}

              {values.type === 'TEXTO' && (
                <>
                  <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                    Texto
                  </Text>
                  <textarea
                    placeholder="Texto"
                    name="text"
                    value={values.text}
                    onChange={handleChange('text')}
                    multiline
                    minRows={4}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      minWidth: '300px',
                      minHeight: '200px',
                      borderRadius: '4px',
                      padding: 10,
                      backgroundColor: Theme.dark.background,
                      marginTop: 0,
                      resize: 'vertical',
                      width: '-webkit-fill-available'
                    }}
                  />
                  {errors.text && touched.text ? (
                    <ErrorText message={errors.text} />
                  ) : null}
                </>
              )}

              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Criar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </S.Box>
    </>
  )
};

export default ModalCreate;
