import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Text from 'components/Text';
import Table from './components/Table';

import Theme from 'style/Theme';

const Chat = () => {
	const [page, setPage] = useState(0);
	const [loadChats, setLoadChats] = useState(true);

	const getChats = useStoreActions(({ chat }) => chat.get);
	const [count = 0, chats = []] = useStoreState(({ chat }) => [chat.count, chat.chats]);

	const handleChangePage = async (value) => {
		setPage(value);
		await getChats({ page: value });
	};

	useEffect(() => {
		async function fetchData() {
			await getChats({ page: 0 });
			setLoadChats(false);
		}

		fetchData();
	}, [0]);

	return (
		<>
			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Chat
					</Text>
				</Grid>
			</div>

			{loadChats ? (
				<Grid container justifyContent="center" alignItems="center">
					<CircularProgress style={{ margin: 25 }} />
				</Grid>
			) : (
				<div style={{ padding: 25, flex: 1, backgroundColor: Theme.dark.background, textAlign: 'center' }}>
					{
						chats.length === 0
							?
							<Text color={Theme.dark.primaryText} bold size={22} style={{ margin: '15px 0' }}>
								Nenhum item para mostrar!
							</Text>
							:
							<Table chats={chats} page={page} handleChangePage={handleChangePage} count={count} />
					}
				</div>
			)}
		</>
	)
};

export default Chat;
