import React, { useState } from 'react';
import Moment from 'moment';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, PlayArrow } from '@mui/icons-material';

import Text from 'components/Text';
import ModalUpdate from './components/ModalUpdate';
import ModalViewContent from './components/ModalViewContent';

import Theme from 'style/Theme';

const TableComponent = ({ content, page = 0, handleChangePage, count = 0, isAdmin = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const navigate = useNavigate();

  const deleteContent = useStoreActions(({ content }) => content.remove);

  const openDeleteDialog = (content) => {
    setSelectedContent(content);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const handleOpenModal = (content) => {
    setSelectedContent(content);
    setShowModal(true);
  };

  const handleViewModal = (contentItem) => {
    if (contentItem?.ObjetoAprendizagem && contentItem.ObjetoAprendizagem.type === 'EXERCICIO') {
      navigate(`/lista-exercicios/${contentItem.ObjetoAprendizagem.exercisesId}`);
    } else {
      setSelectedContent(contentItem);
      setViewModal(true);
    }
  };

  const handleDeleteContent = async () => {
    if (deleteLoading) return
    setDeleteLoading(true);
    const response = await deleteContent(selectedContent);
    setDeleteLoading(false)
    if (response === true) closeDeleteDialog();
  };

  const handleClick = (item) => {
    if (item?.ObjetoAprendizagem && item.ObjetoAprendizagem.type === 'EXERCICIO') {
      navigate(`/lista-exercicios/${item.ObjetoAprendizagem.exercisesId}`);
    }
  };

  const orderedContent = content.sort((a, b) => (a.order > b.order ? 1 : -1))

  return (
    <>
      <Dialog
        open={showDeleteDialog}
        keepMounted
        onClose={closeDeleteDialog}
        aria-describedby="delete-dialog"
      >
        <DialogTitle>Deletar Conteúdo</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <b>Conteúdo: {selectedContent?.id}</b>
            <br />
            Você realmente deseja deletar esse conteúdo?
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancelar</Button>
          <Button
            onClick={() => handleDeleteContent()}
            disabled={deleteLoading}
            style={{ color: 'red', fontWeight: 'bold' }}>
            {deleteLoading ? <CircularProgress size={20} /> : 'DELETAR'}
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalUpdate onClose={() => setShowModal(false)} selectedContent={selectedContent} />
      </Modal>

      <Modal
        open={viewModal}
        onClose={() => setViewModal(false)}
      >
        <ModalViewContent onClose={() => setViewModal(false)} selectedContent={selectedContent} />
      </Modal>

      <TableContainer
        component={Paper}
        style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
      >
        <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Courses table">
          <TableHead>
            <TableRow style={{ fontWeight: 'bold' }}>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  ID
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18} align="center">
                  Ordem
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Tipo
                </Text>
              </TableCell>
              <TableCell>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Criado em
                </Text>
              </TableCell>
              <TableCell align="right" style={{ minWidth: 240 }}>
                <Text color={Theme.dark.primaryText} bold size={18}>
                  Opções
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedContent.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent', cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row" onClick={() => handleClick(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.id}
                  </Text>
                </TableCell>
                <TableCell onClick={() => handleClick(row)}>
                  <Text color={Theme.dark.primaryText} size={15} align="center">
                    {row.order}
                  </Text>
                </TableCell>
                <TableCell onClick={() => handleClick(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {row.ObjetoAprendizagem.type}
                  </Text>
                </TableCell>
                <TableCell onClick={() => handleClick(row)}>
                  <Text color={Theme.dark.primaryText} size={15}>
                    {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                  </Text>
                </TableCell>
                <TableCell align="right" style={{ minWidth: 240 }}>
                  <Button
                    style={{ borderRadius: 4, color: 'white', marginRight: 5 }}
                    onClick={() => handleViewModal(row)}
                    disabled={deleteLoading}
                  >
                    <PlayArrow />
                  </Button>

                  {isAdmin && (
                    <Button
                      style={{ borderRadius: 4, color: 'white', marginRight: 5 }}
                      onClick={() => handleOpenModal(row)}
                      disabled={deleteLoading}
                    >
                      <EditIcon />
                    </Button>
                  )}

                  {isAdmin && (
                    <Button
                      style={{ borderRadius: 4, color: 'white' }}
                      onClick={() => openDeleteDialog(row)}
                      disabled={deleteLoading}
                    >
                      <DeleteIcon />
                      {/*deleteLoading && selectedContent?.id === row.id ? <CircularProgress size={20} /> : <DeleteIcon />*/}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={25}
        rowsPerPageOptions={[25]}
        page={page}
        style={{ color: '#fff' }}
        onPageChange={(e, page) => handleChangePage(page)}
      />
    </>
  );
};

export default TableComponent;