

import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

import { Box, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import Theme from 'style/Theme';

const seeMore = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  width: 'auto',
  height: 'auto',
  bgcolor: Theme.dark.background,
  borderRadius: 4,
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
  p: 4,
};

const SchemaValidation = Yup.object().shape({
  title: Yup.string()
    .required('Campo obrigatório'),
  alternativeA: Yup.string()
    .required('Campo obrigatório'),
  alternativeB: Yup.string()
    .required('Campo obrigatório'),
  alternativeC: Yup.string()
    .required('Campo obrigatório'),
  alternativeD: Yup.string()
    .required('Campo obrigatório'),
  alternativeE: Yup.string()
    .required('Campo obrigatório'),
  indexCorrect: Yup.number()
    .required('Campo obrigatório')
});

const ModalCreate = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const createExercises = useStoreActions(({ exercises }) => exercises.create);

  const handleSubmit = async ({ title, alternativeA, alternativeB, alternativeC, alternativeD, alternativeE, indexCorrect }) => {
    if (loading) return;
    setLoading(true);

    if (!title) return toast.error('Informe o enunciado do exercício.');
    if (!alternativeA || !alternativeB || !alternativeC || !alternativeD || !alternativeE) return toast.error('Informe todas as alternativas.');
    if (!indexCorrect) return toast.error('Informe a alternativa correta.');

    const response = await createExercises({
      title,
      alternatives: [
        {
          description: alternativeA,
          isCorrect: indexCorrect === 1,
          order: 1,
        },
        {
          description: alternativeB,
          isCorrect: indexCorrect === 2,
          order: 2,
        },
        {
          description: alternativeC,
          isCorrect: indexCorrect === 3,
          order: 3,
        },
        {
          description: alternativeD,
          isCorrect: indexCorrect === 4,
          order: 4,
        },
        {
          description: alternativeE,
          isCorrect: indexCorrect === 5,
          order: 5,
        },
      ],
    })
    setLoading(false);

    if (response === true) onClose();
  };

  return (
    <>
      <Box sx={seeMore} style={{ outline: 0, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ flex: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
          <Button style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClose} >
            <CloseIcon style={{ color: Theme.dark.primaryText }} />
          </Button>
        </div>

        <Text color={Theme.dark.primaryText} bold size={22} style={{ marginBottom: 25 }}>
          Criar Exercício
        </Text>

        <Formik
          initialValues={{
            title: '',
            indexCorrect: 1,
            alternativeA: '',
            alternativeB: '',
            alternativeC: '',
            alternativeD: '',
            alternativeE: '',
          }}
          validationSchema={SchemaValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, handleChange, handleSubmit, touched, setFieldValue, values }) => (
            <>
              <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                Enunciado do Exercício
              </Text>
              <textarea
                placeholder="Enunciado do Exercício"
                name="title"
                value={values.title}
                onChange={handleChange('title')}
                multiline
                minRows={4}
                style={{
                  color: Theme.dark.primaryText,
                  border: `1px solid ${Theme.dark.border}`,
                  minWidth: '300px',
                  minHeight: '65px',
                  borderRadius: '4px',
                  padding: 10,
                  backgroundColor: Theme.dark.background,
                  marginTop: 0,
                  resize: 'vertical',
                  width: '-webkit-fill-available'
                }}
              />
              {errors.title && touched.title ? (
                <ErrorText message={errors.title} />
              ) : null}

              <Box style={{ marginTop: 10 }}>
                <Text color={Theme.dark.primaryText} bold size={14} style={{ marginTop: 10, marginBottom: 5 }}>
                  Alternativas:
                </Text>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                  <input
                    style={{ height: 20, width: 20 }}
                    type='radio'
                    name='answer'
                    value={values.indexCorrect === 1}
                    onChange={() => setFieldValue('indexCorrect', 1)}
                  />
                  <span style={{ color: 'white', fontSize: 20 }} >A:</span>
                  <textarea
                    placeholder="Alternativa A"
                    name="alternativeA"
                    value={values.alternativeA}
                    onChange={handleChange('alternativeA')}
                    multiline
                    minRows={2}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 10,
                      backgroundColor: Theme.dark.background,
                      marginTop: 0,
                      marginLeft: 10,
                      resize: 'vertical',
                      width: '-webkit-fill-available'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                  <input
                    style={{ height: 20, width: 20 }}
                    type='radio'
                    name='answer'
                    value={values.indexCorrect === 2}
                    onChange={() => setFieldValue('indexCorrect', 2)}
                  />
                  <span style={{ color: 'white', fontSize: 20 }} >B:</span>
                  <textarea
                    placeholder="Alternativa B"
                    name="alternativeB"
                    value={values.alternativeB}
                    onChange={handleChange('alternativeB')}
                    multiline
                    minRows={2}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 10,
                      backgroundColor: Theme.dark.background,
                      marginTop: 0,
                      marginLeft: 10,
                      resize: 'vertical',
                      width: '-webkit-fill-available'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                  <input
                    style={{ height: 20, width: 20 }}
                    type='radio'
                    name='answer'
                    value={values.indexCorrect === 3}
                    onChange={() => setFieldValue('indexCorrect', 3)}
                  />
                  <span style={{ color: 'white', fontSize: 20 }} >C:</span>
                  <textarea
                    placeholder="Alternativa C"
                    name="alternativeC"
                    value={values.alternativeC}
                    onChange={handleChange('alternativeC')}
                    multiline
                    minRows={2}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 10,
                      backgroundColor: Theme.dark.background,
                      marginTop: 0,
                      marginLeft: 10,
                      resize: 'vertical',
                      width: '-webkit-fill-available'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                  <input
                    style={{ height: 20, width: 20 }}
                    type='radio'
                    name='answer'
                    value={values.indexCorrect === 4}
                    onChange={() => setFieldValue('indexCorrect', 4)}
                  />
                  <span style={{ color: 'white', fontSize: 20 }} >D:</span>
                  <textarea
                    placeholder="Alternativa D"
                    name="alternativeD"
                    value={values.alternativeD}
                    onChange={handleChange('alternativeD')}
                    multiline
                    minRows={2}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 10,
                      backgroundColor: Theme.dark.background,
                      marginTop: 0,
                      marginLeft: 10,
                      resize: 'vertical',
                      width: '-webkit-fill-available'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                  <input
                    style={{ height: 20, width: 20 }}
                    type='radio'
                    name='answer'
                    value={values.indexCorrect === 5}
                    onChange={() => setFieldValue('indexCorrect', 5)}
                  />
                  <span style={{ color: 'white', fontSize: 20 }} >E:</span>
                  <textarea
                    placeholder="Alternativa E"
                    name="alternativeE"
                    value={values.alternativeE}
                    onChange={handleChange('alternativeE')}
                    multiline
                    minRows={2}
                    style={{
                      color: Theme.dark.primaryText,
                      border: `1px solid ${Theme.dark.border}`,
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 10,
                      backgroundColor: Theme.dark.background,
                      marginTop: 0,
                      marginLeft: 10,
                      resize: 'vertical',
                      width: '-webkit-fill-available'
                    }}
                  />
                </div>
              </Box>

              {errors.name && touched.name ? (
                <ErrorText message={errors.name} />
              ) : null}

              <Button
                background={Theme.primary}
                style={{ marginTop: 35, padding: 15, width: '100%', height: 55 }}
                height={60}
                disabled={loading}
                onClick={handleSubmit}
                type="submit"
              >
                {loading ? <CircularProgress size={16} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Criar</Text>}
              </Button>
            </>
          )}
        </Formik>
      </Box>
    </>
  )
};

export default ModalCreate;
