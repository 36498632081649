import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Moment from 'moment';
import { useStoreActions } from 'easy-peasy';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import Text from 'components/Text';
import ModalUpdate from './components/ModalUpdate';

import Theme from 'style/Theme';

const TableComponent = ({ classes, courseId, page = 0, handleChangePage, count = 0, isAdmin = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const navigate = useNavigate();

  const deleteClasses = useStoreActions(({ classes }) => classes.remove);

  const openDeleteDialog = (lesson) => {
    setSelectedClasses(lesson);
    setShowDeleteDialog(true);
  };
  const closeDeleteDialog = () => setShowDeleteDialog(false);

  const handleOpenModal = (lesson) => {
    setSelectedClasses(lesson);
    setShowModal(true);
  };

  const handleDeleteClasses = async () => {
    if (deleteLoading) return;
    setDeleteLoading(true);
    const response = await deleteClasses({ id: selectedClasses.id });
    setDeleteLoading(false);

    if (response === true) closeDeleteDialog();
  };

  const handleClassesId = (row) => {
    if (row.replica) {
      navigate(`/curso/${courseId}/aula/${row.replica}`);
    } else {
      navigate(`/curso/${courseId}/aula/${row.id}`);
    }
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalUpdate
          onClose={() => setShowModal(false)}
          selectedClasses={selectedClasses}
        />
      </Modal>

      <Dialog
        open={showDeleteDialog}
        keepMounted
        onClose={closeDeleteDialog}
        aria-describedby="delete-dialog"
      >
        <DialogTitle>Deletar Aula</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            <b>Aula: {selectedClasses?.name}</b>
            <br />
            Você realmente deseja deletar essa aula?
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancelar</Button>
          <Button
            onClick={handleDeleteClasses}
            disabled={deleteLoading}
            style={{ color: 'red', fontWeight: 'bold' }}>
            {deleteLoading ? <CircularProgress size={20} /> : 'DELETAR'}
          </Button>
        </DialogActions>
      </Dialog>
      {
        classes.length === 0
          ?
          <Text size={20}>
            Nenhum item para mostrar!
          </Text>
          :
          <>

            <TableContainer
              component={Paper}
              style={{ backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
            >
              <Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Classes table">
                <TableHead>

                  <TableRow style={{ fontWeight: 'bold' }}>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        ID
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Nome
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Tema
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Ordem
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        ID Réplica
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Disponível
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color={Theme.dark.primaryText} bold size={18}>
                        Criado em
                      </Text>
                    </TableCell>
                    {isAdmin && (
                      <TableCell align="right">
                        <Text color={Theme.dark.primaryText} bold size={18}>
                          Opções
                        </Text>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classes.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent' }}
                    >

                      <TableCell component="th" scope="row" onClick={() => handleClassesId(row)} style={{ cursor: 'pointer' }}>
                        <Text size={15}>
                          {row.id}
                        </Text>
                      </TableCell>
                      <TableCell onClick={() => handleClassesId(row)} style={{ cursor: 'pointer' }}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {row.name}
                        </Text>
                      </TableCell>
                      <TableCell onClick={() => handleClassesId(row)} style={{ cursor: 'pointer' }}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {row.category}
                        </Text>
                      </TableCell>
                      <TableCell onClick={() => handleClassesId(row)} style={{ cursor: 'pointer' }}>
                        <Text color={Theme.dark.primaryText} size={15} align="center">
                          {row.order}
                        </Text>
                      </TableCell>
                      <TableCell onClick={() => handleClassesId(row)} style={{ cursor: 'pointer' }}>
                        <Text color={Theme.dark.primaryText} size={15} align="center">
                          {row.replica}
                        </Text>
                      </TableCell>
                      <TableCell onClick={() => handleClassesId(row)} style={{ cursor: 'pointer' }}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {Moment(row.date).format('DD/MM/YYYY')}
                        </Text>
                      </TableCell>
                      <TableCell onClick={() => handleClassesId(row)} style={{ cursor: 'pointer' }}>
                        <Text color={Theme.dark.primaryText} size={15}>
                          {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                        </Text>
                      </TableCell>

                      {isAdmin && (
                        <TableCell align="right">
                          <Button
                            style={{ borderRadius: 4, color: 'white', marginRight: 5 }}
                            onClick={() => handleOpenModal(row)}
                            disabled={deleteLoading}
                          >
                            <EditIcon />
                          </Button>

                          <Button
                            style={{ borderRadius: 4, color: 'white' }}
                            onClick={() => openDeleteDialog(row)}
                            disabled={deleteLoading}
                          >
                            {deleteLoading && selectedClasses?.id === row.id ? <CircularProgress size={20} /> : <DeleteIcon />}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={count}
              rowsPerPage={25}
              rowsPerPageOptions={25}
              page={page}
              style={{ color: '#fff' }}
              onPageChange={(e, page) => handleChangePage(page)}
            />
          </>
      }
    </>
  );
};

export default TableComponent;