import { action, thunk } from "easy-peasy";
import { toast } from "react-toastify";

import LearningObjectAPI from 'api/LearningObject';

const errorMessage = 'Ocorreu um erro inesperado, verifique sua conexão ou veja o log de erros.';

export default {
	count: 0,
	learningObjects: [],

	setData: action((state, { type, payload }) => {
		state[type] = payload;
	}),

	get: thunk(async (actions, payload) => {
		try {
			const {
				page = 0,
				id = null,
				type = null,
				video = null,
				audio = null,
				link = null,
				text = null,
				exerciseId = null,
			} = payload;

			let query = `page=${page}`;

			if (id) query = `${query}&id=${id}`;
			if (type) query = `${query}&type=${type}`;
			if (video) query = `${query}&video=${video}`;
			if (audio) query = `${query}&audio=${audio}`;
			if (link) query = `${query}&link=${link}`;
			if (text) query = `${query}&text=${text}`;
			if (exerciseId) query = `${query}&exerciseId=${exerciseId}`;

			const response = await LearningObjectAPI.get(query);
			if (response.ok) {
				actions.setData({ type: 'learningObjects', payload: response?.data?.data });
				actions.setData({ type: 'count', payload: response?.data?.count });
				return response?.data;
			}
		} catch (e) {
			toast.error(e || errorMessage);
			return false;
		}
	}),

	create: thunk(async (actions, payload, { getState }) => {
		try {
			const {
				exercisesId = null,
				type = undefined,
				text = null,
				video = null,
				audio = null
			} = payload;

			if (!type) return toast.error('Informe o tipo do conteúdo.');
			if (!video && !text && !audio) return toast.error('Informe um conteúdo.');
			if (type === 'EXERCICIO' && !exercisesId) return toast.error('Informe uma lista de exercício.');

			const response = await LearningObjectAPI.create(payload);

			if (response?.ok) {
				const { learningObjects, count } = await getState();
				learningObjects.push(response.data);

				actions.setData({ type: 'learningObjects', payload: learningObjects });
				actions.setData({ type: 'count', payload: count + 1 });
				toast.success('Conteúdo adicionado com sucesso.');
				return true;
			}
		} catch (e) {
			return toast.error(e || errorMessage);
		}
	}),

	update: thunk(async (actions, payload, { getState }) => {
		try {
			const {
				id,
				exercisesId = null,
				type = undefined,
				text = null,
				video = null,
				audio = null
			} = payload

			if (!id) return toast.error('Informe o ID do conteúdo.');
			if (!type) return toast.error('Informe o tipo do conteúdo.');
			if (!video && !text && !audio) return toast.error('Informe um conteúdo.');
			if (type === 'EXERCICIO' && !exercisesId) return toast.error('Informe uma lista de exercício.');

			const response = await LearningObjectAPI.update(payload);

			if (response?.ok) {
				const { learningObjects } = await getState();
				let foundedIndex = learningObjects.findIndex(t => t.id === id);
				learningObjects[foundedIndex] = payload;
				actions.setData({ type: 'learningObjects', payload: learningObjects });
				toast.success('Conteúdo atualizado com sucesso.');
				return true;
			}
		} catch (e) {
			return toast.error(e || errorMessage);
		}
	}),

	remove: thunk(async (actions, payload, { getState }) => {
		try {
			const { id = null } = payload;
			if (!id)
				return toast.error('Informe o id do conteúdo que deseja deletar.');

			const response = await LearningObjectAPI.remove(id);
			if (response.ok) {
				const { learningObjects, count } = await getState();
				let foundedIndex = learningObjects.findIndex(t => t.id === id);
				learningObjects.splice(foundedIndex, 1);
				actions.setData({ type: 'learningObjects', payload: learningObjects });
				actions.setData({ type: 'count', payload: count - 1 });

				toast.success('Conteúdo deletado com sucesso!');
				return true;
			}
			throw response?.data?.message;
		} catch (e) {
			toast.error(e || 'Ocorreu um erro inesperado, verifique sua conexão e tente novamente.');
			return false;
		}
	}),
}
