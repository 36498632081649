import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination
} from '@mui/material';

import Text from 'components/Text';
import Theme from 'style/Theme';

const PracticeReports = () => {
	const [page, setPage] = useState(0);
	const [loadGeneralReport, setLoadGeneralReport] = useState(true);

	const getGeneralReport = useStoreActions(({ generalReport }) => generalReport.get);
	const getGeneralReportCsv = useStoreActions(({ generalReport }) => generalReport.getCsv);
	const [count = 0, generalReport = []] = useStoreState(({ generalReport }) => [generalReport.count, generalReport.generalReport]);
	const [loading, setLoading] = useState(false);

	const handleChangePage = async (value) => {
		setPage(value);
		await getGeneralReport({ page: value });
	};

	useEffect(() => {
		async function fetchData() {
			await getGeneralReport({ page: 0 });
			setLoadGeneralReport(false);
		}
		fetchData();
	}, [0]);

	return (
		<>

			<div style={{ paddingTop: 60 }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					style={{ width: '100%', height: '100%', padding: 30, backgroundColor: Theme.dark.secondary }}
				>
					<Text size={22} bold color={Theme.dark.primaryText} style={{ textTransform: 'uppercase' }}>
						Relatório Geral
					</Text>

					<button
						style={{
							backgroundColor: Theme.dark.primary,
							color: Theme.dark.primaryText,
							border: 'none',
							padding: 10,
							borderRadius: 5,
							fontSize: 16,
							cursor: 'pointer',
							width: 200
						}}
						loading={loading}
						onClick={async () => {
							if (loading) return;
							setLoading(true);
							const csvData = await getGeneralReportCsv();
							const blob = new Blob([csvData], { type: "text/csv" });
							const url = window.URL.createObjectURL(blob);
							const a = document.createElement("a");
							a.href = url;
							a.download = "relatorio_geral_ead.csv";
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
							setLoading(false);
						}}
					>
						{loading ? <CircularProgress color="success" size={16} thickness={4} style={{ zIndex: 1 }} />
							: <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>Exportar CSV</Text>}
					</button>

				</Grid>
			</div>

			<TableContainer
				component={Paper}
				style={{ marginRight: 20, marginLeft: 20, paddingBottom: 20, width: '97vw', backgroundColor: 'rgb(30, 38, 45)', borderRadius: 5, border: '1px solid rgb(54, 69, 99)' }}
			>
				<Table sx={{ backgroundColor: 'rgb(30, 38, 45)' }} aria-label="Courses table">
					<TableHead>
						<TableRow style={{ fontWeight: 'bold' }}>

							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									UNIDADE
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									CURSO
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									JOVEM
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									AULA
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									TEMA
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									DATA AULA
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									INÍCIO
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									FIM
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									AULA CONCLUÍDA?
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									ACERTOS
								</Text>
							</TableCell>
							<TableCell>
								<Text color={Theme.dark.primaryText} bold size={12}>
									APRENDIZADO REGISTRADO?
								</Text>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{generalReport.map((row, index) => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								style={{ backgroundColor: index % 2 === 1 ? '#323c44' : 'transparent', width: '150%' }}
							>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.unidade}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.curso}
									</Text>
								</TableCell>
								<TableCell width={200}>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.jovem}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.ordem_aula}
									</Text>
								</TableCell>
								<TableCell width={200}>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.tema}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.data_aula}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.entrada || '-'}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.saida || '-'}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{(row.aula_concluida) ? 'Sim' : 'Não'}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{row.acertos}
									</Text>
								</TableCell>
								<TableCell>
									<Text color={Theme.dark.primaryText} size={12}>
										{(row.aprendizado_registrado) ? 'Sim' : 'Não'}
									</Text>
								</TableCell>

							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				count={count}
				rowsPerPage={15}
				rowsPerPageOptions={[15]}
				page={page}
				style={{ color: '#fff' }}
				onPageChange={(e, page) => handleChangePage(page)}>
			</TablePagination>
		</>
	)
};

export default PracticeReports;
